import BaseService from "./baseService";
import { reloadPublicLinks } from "../redux/actions/index";

export class PublicLinkService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Publiclinks";
    this.name = "Publiclinks";
  }
  getPublicChannel(id) {
    const url = "/api/Publiclinks/get-channel";
    let data = { id: id, timezone: new Date().getTimezoneOffset() };
    return this.post(url, JSON.stringify(data));
  }
  reload() {
    let self = this;
    return this.read().then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadPublicLinks(data));
      }
    });
  }
}

export default PublicLinkService;
