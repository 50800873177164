import React, { Component } from "react";
import {
  CardTitle,
  Card,
  CardHeader,
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { StoreService } from "../../services/storeService";
import { Link } from "react-router-dom";
import { reloadStores } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { CompanyService } from "../../services/companyService";
import { ModalDelete } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import { ChannelService } from "../../services/channelService";
import { BrandService } from "../../services/brandService";
import { ZoneService } from "../../services/zoneService";
import moment from "moment";
const KEYS_TO_FILTERS = ["place", "manager", "email"];

let storeService = new StoreService();
let companyService = new CompanyService();
let channelService = new ChannelService();
let brandService = new BrandService();
let zoneService = new ZoneService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Tienda</th>
          <th>Marca</th>
          {this.props.viewButton && <th>Cliente</th>}
          <th>Zona</th>
          <th>Computador</th>
          {this.props.viewButton && (
            <React.Fragment>
              <th>Contacto</th>
              <th>Teléfono</th>
              <th>Email</th>
            </React.Fragment>
          )}
          <th>Ultima Canción</th>
          <th>Tiempo del Computador</th>
          <th>Ultima Conexión</th>
          <th>IP</th>
          <th>Volumen</th>
          <th>Estado</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}

const MINUTE = 60 * 1000;
const TEN_MINUTES = MINUTE * 10
const HOUR = MINUTE * 60;

class Connection extends Component {
  render() {
    let lastConnection = this.props.lastConnection;
    if (lastConnection) {
      let date = new Date();
      let connectionDate = new Date(lastConnection);
      console.log(date - connectionDate);
      let dif = date - connectionDate;
      if (dif < TEN_MINUTES) return <td className="text-success">Conectado</td>;
      else if (dif > HOUR)
        return <td className="text-danger">Desconectado</td>;
      return <td className="text-warning">Conectado</td>;
    }
    return <td className="text-danger">-</td>;
  }
}

class Store extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    storeService.deleteById(this.props.item.id).then(function(data) {
      storeService.reload();
      self.context.getNotificationSystem().addNotification({
        message:
          "Se ha eliminado exitosamente la Tienda: " +
          self.props.item.name +
          ".",
        title: "Tienda Eliminada",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let deleting = this.state.deleting;
    let item = this.props.item;
    return (
      <tr>
        <td>{item.name}</td>
        <td>{brandService.MatchName(item.brandId)}</td>
        {this.props.viewButton && (
          <td>{companyService.MatchName(item.companyId)}</td>
        )}
        <td>{zoneService.MatchName(item.zoneId)}</td>
        <td>{item.computerId}</td>
        {this.props.viewButton && (
          <React.Fragment>
            <td>{item.manager}</td>
            <td>{item.telephone}</td>
            <td>{item.email}</td>
          </React.Fragment>
        )}
        <td>
          {item.songName ? item.songName : "No ha tocado ninguna canción"}
        </td>
        <td>
          {item.computerTime
            ? moment(item.computerTime).format("DD/MM/YYYY HH:mm")
            : "-"}
        </td>
        <td>
          {item.lastConnection
            ? moment(item.lastConnection).format("DD/MM/YYYY HH:mm")
            : "Nunca se ha Conectado"}
        </td>
        <td>
          {item.ip}
        </td>
        <td>
          {item.volumen}
        </td>
        <Connection lastConnection={item.lastConnection} />
        <td className="text-center">
          <ButtonGroup className="mr-3" aria-label="First group">
            {this.props.viewButton && (
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={item.name}
                    entityName="una Tienda"
                  />
                )}
              </Button>
            )}

            <Link
              to={"/stores/view/" + item.id}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye" />
            </Link>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

Store.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class StoreConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: ""
    };
    channelService.reload();
    companyService.reload();
    brandService.reload();
    zoneService.reload();
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  loadData(self) {
    storeService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadStores(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let { data, user } = this.props;
    let company = this.state.company;
    let filtered = data;
    if (company) {
      filtered = filtered.filter(x => company === x.companyId);
    }
    filtered = filtered.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-0">Tiendas</CardTitle>
              {user.role === "ADMIN" && (
                <h6 className="mb-1">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Compañia</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      id="company"
                      value={company}
                      onChange={this.handleChange}
                    >
                      <option value="">Cualquier Compañia</option>
                      {this.props.companies.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                </h6>
              )}
              <h6 className="mb-1 col-sm-5">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </h6>
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={Store}
            loading={this.state.loading}
            bordered={false}
            striped={false}
            viewButton={user.role === "ADMIN"}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.stores,
    companies: state.companies,
    user: state.user,
    channels: state.channels,
    zones: state.zones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadStores: stores => dispatch(reloadStores(stores))
  };
};

const Stores = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreConnector);

export default Stores;
