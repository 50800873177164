import BaseService from "./baseService";
import { reloadCompanies } from "../redux/actions/index";

export class CompanyService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Companies";
    this.name = "Companies";
  }
  picture(company) {
    return "/api/containers/company/download/" + company;
  }
  MatchName(id) {
    let answer = this.searchId(this.store.getState().companies, id);
    return answer ? answer.name : "No asignado";
  }
  changeConfig(data) {
    const url = "/api/Companies/change-config";
    return this.post(url, JSON.stringify(data));
  }
  reload() {
    let self = this;
    let filter = {
      include: "brand"
    };
    return this.get(this.getUrl(filter)).then(function (data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadCompanies(data));
      }
    });
  }
}

export default CompanyService;
