import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { PublicLinkService } from "../../services/publicLinkService";
import { Redirect } from "react-router";
import { ChannelService } from "../../services/channelService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import moment from "moment";

let publicLinkService = new PublicLinkService();
let channelService = new ChannelService();

class EditPublicLinkConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      availableDate: new Date(),
      channelId: "",
      loading: !creating,
      creating: creating,
      success: false
    };
    channelService.reload();
    this.handleChange = this.handleChange.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    if (!creating) this.loadData();
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleName(event) {
    this.setState({
      name: event.target.value
        .toLowerCase()
        .replace(/\s/g, "")
        .replace(/[^a-zA-Z0-9-_]/g, "")
    });
  }
  handleDayChange(availableDate) {
    this.setState({ availableDate: availableDate });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const { availableDate, channelId, name } = this.state;
    let realDate = moment(availableDate);
    realDate.set({ h: 23, m: 59 });
    let req = {
      availableDate: realDate,
      channelId: channelId,
      name: name
    };
    console.log(JSON.stringify(req));
    return publicLinkService
      .createOrEdit(req, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "Se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente el Link Publico",
            level: "success"
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    publicLinkService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          self.setState(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const {
      creating,
      availableDate,
      channelId,
      success,
      loading,
      name
    } = this.state;
    if (success) return <Redirect to="/publicLinks" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating
                        ? "Agregando Link Publico"
                        : "Editando Link Publico"}
                    </CardTitle>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          disabled={loading}
                          type="text"
                          id="name"
                          value={name}
                          onChange={this.handleName}
                        />
                      </InputGroup>
                      <div className="mb-3 small">
                        No te permite escribir caracteres que no se
                        puedan ocupar en una url.
                      </div>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Fecha de Termino</InputGroupText>
                        </InputGroupAddon>
                        <DayPickerInput
                          placeholder={moment(availableDate).format("DD/MM/YY")}
                          onDayChange={this.handleDayChange}
                          formatDate={formatDate}
                          format="DD/MM/YYYY"
                          inputProps={{ className: "form-control" }}
                          parseDate={parseDate}
                          isDisabled={loading}
                          dayPickerProps={{
                            locale: "es",
                            localeUtils: MomentLocaleUtils
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Canal</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          disabled={loading}
                          type="select"
                          id="channelId"
                          value={channelId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona un Canal</option>
                          {this.props.channels.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditPublicLinkConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    channels: state.channels
  };
};

const EditPublicLink = connect(
  mapStateToProps,
  null
)(EditPublicLinkConnector);

export default EditPublicLink;
