import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { loginUser } from "../../../redux/actions/index";
import { connect } from "react-redux";
import { AuthService } from "../../../services/authService";
import PropTypes from "prop-types";

let authService = new AuthService();

const mapDispatchToProps = dispatch => {
  return {
    loginUser: user => dispatch(loginUser(user))
  };
};

class FirstLoginConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      password: "",
      password2: "",
      name: "",
      username: "",
      telephone: "",
      email: "",
      role: "CLIENT",
      code: "",
      companyId: "",
      brandId: "",
      success: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event, cosa, user) {
    event.preventDefault();
    let self = this;
    const { oldPassword, password, password2 } = this.state;
    if (password !== password2) {
      self.context.getNotificationSystem().addNotification({
        title: "Error en la Contraseña",
        message: "Las Contraseñas Ingresadas no son Iguales",
        level: "error"
      });
      return;
    }

    authService.changeOldPassword(oldPassword, password).then(
      function (response) {
        console.log(response.status);
        if (response.status === 400) {
          self.context.getNotificationSystem().addNotification({
            message:
              "No se pudo cambiar la contraseña , contraseña actual incorrecta",
            title: " Error Cambiando Contraseña",
            level: "error"
          });
          return;
        }
        let req = {
          name: user.name,
          telephone: user.telephone,
          username: authService.cleanRut(user.username),
          rut: authService.formatRut(user.username),
          email: user.email.toLowerCase(),
          companyId: user.companyId,
          brandId: user.brandId,
          code: user.code,
          role: user.role,
          costCenterId: user.costCenter ? user.costCenter.id : null,
          contractorId: user.contractor ? user.contractor.id : null,
          firstLogin: false
          // Esta es la parte importante
        };


        authService.createOrEdit(req, cosa)
          .then(function (data) {
            if (!data.hasOwnProperty("error")) {
              self.setState({
                success: true
              });
            } else {
              console.log("Error", JSON.stringify(data));
            }
          });

        return;

      });
  }


  render() {
    let { password, password2, oldPassword, success } = this.state;
    let cosa = this.props.user.id;
    let user = this.props.user;
    if (success) window.location.reload();
    return (
      <div style={{ position: "relative" }}>
        <div className="app flex-row align-items-center">
          <div className="login-logos">
            <img
              src="/assets/img/brand/logo3.png"
              width="50"
              height="50"
              alt="Acroventus"
              className="acroventus-login"
            />
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="login-logos hidden-md-up" />
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <form onSubmit={(e) => this.handleSubmit(e, cosa, user)}>
                        <h1>Cambiar Contraseña</h1>
                        <p className="text-muted">Ingresa tu nueva contraseña</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            requiered
                            type="password"
                            placeholder="Ingresa la contraseña actual"
                            id="oldPassword"
                            value={oldPassword}
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Ingresa la nueva contraseña"
                            id="password"
                            value={password}
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Confirma la nueva contraseña"
                            id="password2"
                            value={password2}
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button color="primary" className="px-4 text-center">
                              Ingresar
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                  <Card
                    className="text-white bg-primary py-5 d-md-down-none"
                    style={{ width: 30 + "%" }}
                  >
                    <CardBody className="text-center">
                      <div>
                        <img
                          src="/assets/img/brand/rino-logo.png"
                          className="img-fluid rinomedia-login"
                          alt="Rinomedia"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
                <CardGroup className="d-lg-none">
                  <Card className="text-white bg-primary">
                    <CardBody className="text-center">
                      <div>
                        <img
                          src="/assets/img/brand/rino-logo.png"
                          className="img-fluid rinomedia-login"
                          alt="Rinomedia"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="login-version">
          Versión: {process.env.REACT_APP_VERSION}
        </div>
      </div>
    );
  }
}

FirstLoginConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { user: state.user };
};

const FirstLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstLoginConnector);

export default FirstLogin;
