export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_WORKER = "LOGIN_WORKER";
export const LOGOUT_WORKER = "LOGOUT_WORKER";
export const RELOAD_COMPANIES = "RELOAD_COMPANIES";
export const R_C_COMPANY = "R_C_COMPANY";
export const RELOAD_STOREROOMS = "RELOAD_STOREROOMS";
export const R_C_STOREROOM = "R_C_STOREROOM";
export const RELOAD_SUPPLIES = "RELOAD_SUPPLIES";
export const R_C_SUPPLY = "R_C_SUPPLY";
export const RELOAD_TOOLS = "RELOAD_TOOLS";
export const R_C_TOOL = "R_C_TOOL";
export const RELOAD_TOOLINSTANCES = "RELOAD_TOOLINSTANCES";
export const R_C_TOOLINSTANCE = "R_C_TOOLINSTANCE";
export const RELOAD_FAMILIES = "RELOAD_FAMILIES";
export const R_C_FAMILY = "R_C_FAMILY";
export const RELOAD_TYPES = "RELOAD_TYPES";
export const RELOAD_PROVIDERS = "RELOAD_PROVIDERS";
export const R_C_TYPE = "R_C_TYPE";
export const RELOAD_REQUESTS = "RELOAD_REQUESTS";
export const R_C_REQUEST = "R_C_REQUEST";
export const RELOAD_USERS = "RELOAD_USERS";
export const RELOAD_INVENTORIES = "RELOAD_INVENTORIES";
export const R_C_INVENTORY = "R_C_INVENTORY";
export const RELOAD_HOME = "RELOAD_HOME";
export const RELOAD_CURRENT_INVENTORY = "RELOAD_CURRENT_INVENTORY";
export const RELOAD_CURRENT_ORDER = "RELOAD_CURRENT_ORDER";
export const ADD_SELECTEDREQUEST = "ADD_SELECTEDREQUEST";
export const REMOVE_SELECTEDREQUEST = "REMOVE_SELECTEDREQUEST";
export const RELOAD_COST_CENTER = "RELOAD_COST_CENTER";
export const RELOAD_SERVICE_ORDER = "RELOAD_SERVICE_ORDER";
export const TOOGLE_SELECTEDORDER = "TOOGLE_SELECTEDORDER";
export const RELOAD_CURRENT_RETURN = "RELOAD_CURRENT_RETURN";
export const ADD_DASHBOARD_VALUE = "ADD_DASHBOARD_VALUE";
export const RELOAD_NOTIFICATION = "RELOAD_NOTIFICATION";
export const RELOAD_CONTRACTORS = "RELOAD_CONTRACTORS";
export const RELOAD_DEVICES = "RELOAD_DEVICES";
export const RELOAD_CHANNELS = "RELOAD_CHANNELS";
export const RELOAD_STORES = "RELOAD_STORES";
export const R_C_STORE = "R_C_STORE";
export const RELOAD_ZONES = "RELOAD_ZONES";
export const RELOAD_PUBLIC_LINKS = "RELOAD_PUBLIC_LINKS";
export const RELOAD_ERROR_SONGS = "RELOAD_ERROR_SONGS";
