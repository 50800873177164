import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { StoreService } from "../../services/storeService";
import { Redirect } from "react-router";
import { CompanyService } from "../../services/companyService";
import { ChannelService } from "../../services/channelService";
import { ZoneService } from "../../services/zoneService";
import { BrandService } from "../../services/brandService";
import PropTypes from "prop-types";
import { connect } from "react-redux";

let storeService = new StoreService();
let companyService = new CompanyService();
let channelService = new ChannelService();
let brandService = new BrandService();
let zoneService = new ZoneService();

class EditStoreConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      name: "",
      manager: "",
      email: "",
      telephone: "",
      companyId: "",
      brandId: "",
      zoneId: "",
      loading: !creating,
      creating: creating,
      success: false
    };
    companyService.reload();
    channelService.reload();
    brandService.reload();
    zoneService.reload();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBrand = this.handleBrand.bind(this);
    if (!creating) this.loadData();
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleBrand(event) {
    let brand = this.props.brands.find(x => event.target.value === x.id);
    this.setState({
      brandId: brand ? brand.id : "",
      channelId: brand ? brand.channelId : "",
      zoneId: ""
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const {
      name,
      manager,
      email,
      telephone,
      companyId,
      brandId,
      zoneId
    } = this.state;
    let req = {
      name: name,
      manager: manager,
      email: email.toLowerCase(),
      telephone: telephone,
      companyId: companyId,
      brandId: brandId,
      zoneId: zoneId
    };
    storeService
      .createOrEdit(req, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "Se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente un Tienda.",
            level: "success"
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    storeService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          self.setState(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const {
      creating,
      name,
      telephone,
      manager,
      email,
      loading,
      success,
      companyId,
      brandId,
      zoneId
    } = this.state;
    let zones = this.props.zones.filter(x => x.brandId === brandId);
    if (success) return <Redirect to="/stores" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating ? "Agregando Tienda" : "Editando Tienda"}
                    </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Encargado</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="manager"
                          value={manager}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Email</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Telefono</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="telephone"
                          value={telephone}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Compañia</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="companyId"
                          value={companyId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona una Compañia</option>
                          {this.props.companies.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Marca</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="brandId"
                          value={brandId}
                          onChange={this.handleBrand}
                        >
                          <option value="">Selecciona una Marca</option>
                          {this.props.brands
                            .filter(x => x.companyId === companyId)
                            .map((e, i) => {
                              return (
                                <option key={i} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            })}
                        </Input>
                      </InputGroup>
                      {zones.length !== 0 && (
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Zona</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            type="select"
                            id="zoneId"
                            value={zoneId}
                            onChange={this.handleChange}
                          >
                            <option value="">Selecciona una Zona</option>
                            {zones.map((e, i) => {
                              return (
                                <option key={i} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      )}
                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditStoreConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    companies: state.companies,
    user: state.user,
    channels: state.channels,
    brands: state.brands,
    zones: state.zones
  };
};

const EditStore = connect(
  mapStateToProps,
  null
)(EditStoreConnector);

export default EditStore;
