import BaseService from "./baseService";
import { reloadChannels } from "../redux/actions/index";

export class ChannelService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Channels";
    this.name = "Channels";
  }
  MatchName(id) {
    let answer = this.searchId(this.store.getState().channels, id);
    return answer ? answer.name : "-";
  }
  Match(id) {
    let answer = this.searchId(this.store.getState().channels, id);
    return answer ? answer : {};
  }
  reload() {
    let self = this;
    return this.read().then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadChannels(data));
      }
    });
  }
}

export default ChannelService;
