import React, { Component } from "react";
import {
  Row,
  Col,
  ListGroupItem,
  Jumbotron
} from "reactstrap";
import { PublicLinkService } from "../../services/publicLinkService";
import AudioPlayer from "../Reproductor/index";

let publicLinkService = new PublicLinkService();

function removeChannel(title) {
  let newTitle = title.split("/");
  return newTitle[newTitle.length - 1].slice(0, -4);
}

class Reproductor extends Component {
  render() {
    if (this.props.invalid)
      return (
        <div className="alert alert-danger" role="alert">
          Tiempo de Prueba terminado
        </div>
      );
    if (!this.props.channel)
      return (
        <div className="alert alert-primary" role="alert">
          Cargando Canal
        </div>
      );
    if (this.props.songList && this.props.songList.length > 0)
      return (
        <div className="animated fadeIn">
          <AudioPlayer songs={this.props.songList} />
        </div>
      );
    return (
      <div className="alert alert-warning" role="alert">
        Este Canal no tiene Canciones
      </div>
    );
  }
}

class ViewPublicLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id ? this.props.id : this.props.match.params.id,
      channel: {},
      songs: [],
      loading: true
    };
    this.mounted = true;
    this.loadData();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id !== prevState.id) {
      return { update: true, id: nextProps.id };
    }
    return { update: false };
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  loadData() {
    let self = this;
    publicLinkService
      .getPublicChannel(this.state.id)
      .then(function (data) {
        data.loading = false;
        if (!data.hasOwnProperty("error")) {
          let result = [];
          for (let i in data.songs) {
            if (data.songs[i].title) {
              //console.log(data.songs[i]);
              data.songs[i].artist = { song: removeChannel(data.songs[i].title) + (data.songs[i].time ? ". Hora: " + data.songs[i].time : "") };
              result.push(data.songs[i]);
            }

          }
          data.songs = result;
          if (result.length === 0) {
            data.invalid = true;
          }
        }
        self.setState(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { channel, songs, loading, error, invalid } = this.state;
    if (loading)
      return (
        <div className="animated fadeIn">
          <Jumbotron>
            <Row>
              <Col xs="12" sm="12" lg="7">
                <h3 className="display-3">Cargando...</h3>
              </Col>
            </Row>
          </Jumbotron>
        </div>
      );
    if (error)
      return (
        <div className="animated fadeIn">
          <Jumbotron>
            <Row>
              <Col xs="12" sm="12" lg="7">
                <h3 className="display-3">Ocurrio un error</h3>
                <p className="lead">
                  Esto puede deberse a que el enlace ya no existe o esta mal
                  escrito
                </p>
              </Col>
            </Row>
          </Jumbotron>
        </div>
      );
    return (
      <div className="animated fadeIn">
        <Jumbotron>
          <Row>
            <Col xs="12" sm="12" lg="7">
              <h3 className="display-3">Preview: {channel.name}</h3>
              <p className="lead">
                Descripción: {channel.description}
                <br />
                Mood: {channel.mood}
              </p>
              <hr className="my-2" />
              <p>Artistas: {channel.artists}</p>
            </Col>
            <Col xs="12" sm="12" lg="5" className="mb-3 p-0">
              <Reproductor songList={songs} channel={channel.id} invalid={invalid} />

            </Col>
            <Col xs="12" sm="12" lg="12">
              {this.state.songs.map((x, i) => (
                <ListGroupItem
                  className="col-md-6"
                  style={{ display: "inline-flex" }}
                  key={i}
                >
                  {x.artist.song}
                </ListGroupItem>
              ))}
            </Col>
          </Row>
        </Jumbotron>
      </div>
    );
  }
}

export default ViewPublicLink;
