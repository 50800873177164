import {
  Brand,
  BrandEdit,
  BrandView,
  Zone,
  ZoneEdit,
  Channel,
  ChannelEdit,
  Company,
  CompanyEdit,
  CompanyView,
  CompanyConfig,
  User,
  UserEdit,
  UserView,
  Help,
  Dashboard,
  Device,
  Store,
  StoreEdit,
  StoreView,
  PublicLink,
  PublicLinkEdit
} from "./views";
import ErrorSongs from './views/ErrorSong/ErrorSongs';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
//{ path: "/inventory", exact: true, name: "Inventario", component: Inventory },

const routesCompany = [
  { path: "/companies", exact: true, name: "Compañias", component: Company },
  {
    path: "/companies/create",
    exact: true,
    name: "Crear",
    component: CompanyEdit
  },
  {
    path: "/companies/edit/:id",
    exact: true,
    name: "Editar",
    component: CompanyEdit
  },
  {
    path: "/companies/view/:id",
    exact: true,
    name: "Ver",
    component: CompanyView
  }
];

const routesPublicLink = [
  {
    path: "/publiclinks",
    exact: true,
    name: "Compañias",
    component: PublicLink
  },
  {
    path: "/publiclinks/create",
    exact: true,
    name: "Crear",
    component: PublicLinkEdit
  },
  {
    path: "/publiclinks/edit/:id",
    exact: true,
    name: "Editar",
    component: PublicLinkEdit
  }
];
const routesChannel = [
  { path: "/channels", exact: true, name: "Marcas", component: Channel },
  {
    path: "/channels/create",
    exact: true,
    name: "Crear",
    component: ChannelEdit
  },
  {
    path: "/channels/edit/:id",
    exact: true,
    name: "Editar",
    component: ChannelEdit
  }
];
const routesBrandCRUD = [
  { path: "/brands", exact: true, name: "Marcas", component: Brand },
  {
    path: "/brands/create",
    exact: true,
    name: "Crear",
    component: BrandEdit
  },
  {
    path: "/brands/edit/:id",
    exact: true,
    name: "Editar",
    component: BrandEdit
  },
  {
    path: "/brands/view/:id",
    exact: true,
    name: "Editar",
    component: BrandView
  }
];
const routesStoreCRUD = [
  { path: "/stores", exact: true, name: "Tiendas", component: Store },
  { path: "/stores/view/:id", exact: true, name: "Ver", component: StoreView },
  {
    path: "/stores/create",
    exact: true,
    name: "Crear",
    component: StoreEdit
  },
  {
    path: "/stores/edit/:id",
    exact: true,
    name: "Editar",
    component: StoreEdit
  }
];
const routesStore = [
  { path: "/stores", exact: true, name: "Tiendas", component: Store },
  { path: "/stores/view/:id", exact: true, name: "Ver", component: StoreView }
];
const routesBrand = [
  { path: "/brands", exact: true, name: "Marcas", component: Brand },
  {
    path: "/brands/view/:id",
    exact: true,
    name: "Editar",
    component: BrandView
  }
];
const routesZoneCRUD = [
  { path: "/zones", exact: true, name: "Tiendas", component: Zone },
  {
    path: "/zones/create",
    exact: true,
    name: "Crear",
    component: ZoneEdit
  },
  {
    path: "/zones/edit/:id",
    exact: true,
    name: "Editar",
    component: ZoneEdit
  }
];
const routesUser = [
  { path: "/users", exact: true, name: "Usuarios", component: User },
  { path: "/users/create", exact: true, name: "Crear", component: UserEdit },
  { path: "/users/view/:id", exact: true, name: "Ver", component: UserView },
  { path: "/users/edit/:id", exact: true, name: "Editar", component: UserEdit }
];

const routesDevice = [
  {
    path: "/devices",
    exact: true,
    name: "Dispositivos",
    component: Device
  }
];

const routesErrorSongs = [
  {
    path: "/errorSongs",
    exact: true,
    name: "Errores de Canciones",
    component: ErrorSongs
  }
];

const routes = [
  {
    path: "/home",
    exact: true,
    name: "Monitoreo de Tiendas",
    component: Dashboard
  },
  { path: "/help", exact: true, name: "Inicio", component: Help },
  { path: "/configure", exact: true, name: "Configuración", component: CompanyConfig }
];

function concatRoutes(...args) {
  return args.reduce((acc, val) => [...acc, ...val]);
}

function GetRoutes(role) {
  switch (role) {
    case "ADMIN":
      return concatRoutes(
        routes,
        routesCompany,
        routesBrandCRUD,
        routesStoreCRUD,
        routesUser,
        routesChannel,
        routesDevice,
        routesZoneCRUD,
        routesPublicLink,
        routesErrorSongs
      );
    case "BILLING":
      return concatRoutes(routes);
    case "CLIENT":
      return concatRoutes(routes, routesBrand, routesDevice, routesStore);
    default:
      return [];
  }
}

export default GetRoutes;
