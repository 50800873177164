import React, { Component } from "react";
import { PropTypes } from "prop-types";

import NotificationSystem from "react-notification-system";

class NotificationProvider extends Component {
	static propTypes = {
		children: PropTypes.node
	};

	static defaultProps = {
		children: ""
	};

	static childContextTypes = {
		getNotificationSystem: PropTypes.func
	};

	getChildContext() {
		return {
			getNotificationSystem: this.getNotificationSystem.bind(this)
		};
	}

	getNotificationSystem() {
		return this.notification;
	}

	render() {
		return (
			<div>
				{this.props.children}
				<NotificationSystem
					ref={ref => {
						this.notification = ref;
					}}
				/>
			</div>
		);
	}
}

export default NotificationProvider;
