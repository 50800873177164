import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_WORKER,
  LOGOUT_WORKER,
  RELOAD_USERS,
  RELOAD_COMPANIES,
  R_C_COMPANY,
  RELOAD_STOREROOMS,
  R_C_STOREROOM,
  RELOAD_SUPPLIES,
  R_C_SUPPLY,
  RELOAD_TOOLS,
  R_C_TOOL,
  RELOAD_TOOLINSTANCES,
  R_C_TOOLINSTANCE,
  RELOAD_FAMILIES,
  R_C_FAMILY,
  RELOAD_TYPES,
  R_C_TYPE,
  RELOAD_REQUESTS,
  R_C_REQUEST,
  RELOAD_INVENTORIES,
  R_C_INVENTORY,
  RELOAD_HOME,
  RELOAD_CURRENT_INVENTORY,
  RELOAD_CURRENT_ORDER,
  ADD_SELECTEDREQUEST,
  REMOVE_SELECTEDREQUEST,
  RELOAD_PROVIDERS,
  RELOAD_COST_CENTER,
  RELOAD_SERVICE_ORDER,
  TOOGLE_SELECTEDORDER,
  RELOAD_CURRENT_RETURN,
  ADD_DASHBOARD_VALUE,
  RELOAD_NOTIFICATION,
  RELOAD_CONTRACTORS,
  RELOAD_DEVICES,
  RELOAD_CHANNELS,
  RELOAD_STORES,
  R_C_STORE,
  RELOAD_ZONES,
  RELOAD_PUBLIC_LINKS,
  RELOAD_ERROR_SONGS
} from "../constants/action-types";

export const addDashboardValue = start => ({
  type: ADD_DASHBOARD_VALUE,
  payload: start
});

export const loginUser = user => ({
  type: LOGIN_USER,
  payload: user
});

export const logoutUser = {
  type: LOGOUT_USER,
  payload: null
};

export const loginWorker = worker => ({
  type: LOGIN_WORKER,
  payload: worker
});

export const logoutWorker = {
  type: LOGOUT_WORKER,
  payload: null
};

export const reloadCompanies = companies => ({
  type: RELOAD_COMPANIES,
  payload: companies
});

export const Company = company => ({
  type: R_C_COMPANY,
  payload: company
});

export const reloadBrands = brands => ({
  type: RELOAD_STOREROOMS,
  payload: brands
});

export const loadBrand = brand => ({
  type: R_C_STOREROOM,
  payload: brand
});

export const reloadSupplies = supplies => ({
  type: RELOAD_SUPPLIES,
  payload: supplies
});

export const Supply = supply => ({
  type: R_C_SUPPLY,
  payload: supply
});

export const reloadTools = tools => ({
  type: RELOAD_TOOLS,
  payload: tools
});

export const Tool = tool => ({
  type: R_C_TOOL,
  payload: tool
});

export const reloadToolsInstance = toolInstances => ({
  type: RELOAD_TOOLINSTANCES,
  payload: toolInstances
});

export const ToolInstance = toolInstance => ({
  type: R_C_TOOLINSTANCE,
  payload: toolInstance
});

export const reloadInventories = inventories => ({
  type: RELOAD_INVENTORIES,
  payload: inventories
});

export const Inventory = inventory => ({
  type: R_C_INVENTORY,
  payload: inventory
});

export const reloadFamilies = families => ({
  type: RELOAD_FAMILIES,
  payload: families
});

export const Family = family => ({
  type: R_C_FAMILY,
  payload: family
});

export const reloadTypes = types => ({
  type: RELOAD_TYPES,
  payload: types
});

export const reloadProviders = types => ({
  type: RELOAD_PROVIDERS,
  payload: types
});

export const reloadContractors = types => ({
  type: RELOAD_CONTRACTORS,
  payload: types
});

export const reloadCostCenters = types => ({
  type: RELOAD_COST_CENTER,
  payload: types
});

export const reloadServiceOrders = types => ({
  type: RELOAD_SERVICE_ORDER,
  payload: types
});

export const Type = type => ({
  type: R_C_TYPE,
  payload: type
});

export const reloadRequests = requests => ({
  type: RELOAD_REQUESTS,
  payload: requests
});

export const Request = request => ({
  type: R_C_REQUEST,
  payload: request
});

export const reloadUsers = users => ({
  type: RELOAD_USERS,
  payload: users
});

export const reloadHome = requests => ({
  type: RELOAD_HOME,
  payload: requests
});

export const reloadCurrentInventory = inventory => ({
  type: RELOAD_CURRENT_INVENTORY,
  payload: inventory
});

export const reloadCurrentOrder = order => ({
  type: RELOAD_CURRENT_ORDER,
  payload: order
});

export const addSelectedRequest = request => ({
  type: ADD_SELECTEDREQUEST,
  payload: request
});

export const removeSelectedRequest = request => ({
  type: REMOVE_SELECTEDREQUEST,
  payload: request
});

export const toogleSelectedOrder = request => ({
  type: TOOGLE_SELECTEDORDER,
  payload: request
});

export const reloadCurrentReturn = request => ({
  type: RELOAD_CURRENT_RETURN,
  payload: request
});

export const reloadNotification = request => ({
  type: RELOAD_NOTIFICATION,
  payload: request
});

export const reloadDevices = request => ({
  type: RELOAD_DEVICES,
  payload: request
});

export const reloadChannels = request => ({
  type: RELOAD_CHANNELS,
  payload: request
});

export const reloadStores = request => ({
  type: RELOAD_STORES,
  payload: request
});

export const loadStore = brand => ({
  type: R_C_STORE,
  payload: brand
});

export const reloadZones = request => ({
  type: RELOAD_ZONES,
  payload: request
});

export const reloadPublicLinks = request => ({
  type: RELOAD_PUBLIC_LINKS,
  payload: request
});

export const reloadErrorSongs = request => ({
  type: RELOAD_ERROR_SONGS,
  payload: request
});



