import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { loginUser } from "../../../redux/actions/index";
import { connect } from "react-redux";
import { AuthService } from "../../../services/authService";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

let authService = new AuthService();

const mapDispatchToProps = dispatch => {
  return {
    loginUser: user => dispatch(loginUser(user))
  };
};

class LoginConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  downloadInstaller() {
    window.open('https://rinodownload.s3-us-west-2.amazonaws.com/rinocerontemedia.zip')
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const { username, password } = this.state;
    if (username === "")
      return this.context.getNotificationSystem().addNotification({
        message: "Ingrese usuario.",
        title: "Error",
        level: "error"
      });
    if (password === "")
      return this.context.getNotificationSystem().addNotification({
        message: "Ingrese contraseña.",
        title: "Error",
        level: "error"
      });
    return authService.login(username, password).then(function (response) {
      if (response.hasOwnProperty("error")) {
        if (response.error === 401) {
          self.context.getNotificationSystem().addNotification({
            message: "Usuario o contraseña inválida.",
            title: "Error",
            level: "error"
          });
        } else {
          self.context.getNotificationSystem().addNotification({
            message: "Intentelo nuevamente mas tarde.",
            title: "Error en el servidor",
            level: "error"
          });
        }
      } else {
        self.props.loginUser(response);
        self.context.getNotificationSystem().addNotification({
          message: "Ha iniciado correctamente su sesion.",
          title: "Inicio Exitoso",
          level: "success"
        });
      }
    });
  }
  render() {
    const { username, password } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <div className="app flex-row align-items-center">
          <div className="login-logos">
            <img
              src="/assets/img/brand/logo3.png"
              width="50"
              height="50"
              alt="Acroventus"
              className="acroventus-login"
            />
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <div className="login-logos hidden-md-up" />
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <form onSubmit={this.handleSubmit}>
                        <h1>Ingreso</h1>
                        <p className="text-muted">Ingresa tus credenciales</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-user-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Nombre de Usuario"
                            id="username"
                            value={username}
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Contraseña"
                            id="password"
                            value={password}
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button color="primary" className="px-4">
                              Login
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Link to="/password" className="btn btn-link px-0">
                              ¿Olvidaste tu <br />contraseña?
                            </Link>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                  <Card
                    className="text-white bg-primary py-5 d-md-down-none"
                    style={{ width: 44 + "%" }}
                  >
                    <CardBody className="text-center">
                      <div>
                        <img
                          src="/assets/img/brand/rino-logo.png"
                          className="img-fluid rinomedia-login"
                          alt="Rinomedia"
                        />
                      </div>
                      <div className="mt-2">
                        <Button color="primary" className="px-4" onClick={this.downloadInstaller}>
                          Descargar Instalador
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
                <CardGroup className="d-lg-none">
                  <Card className="text-white bg-primary">
                    <CardBody className="text-center">
                      <div>
                        <img
                          src="/assets/img/brand/rino-logo.png"
                          className="img-fluid rinomedia-login"
                          alt="Rinomedia"
                        />
                      </div>
                      <div className="mt-2">
                        <Button color="primary" className="px-4" onClick={this.downloadInstaller}>
                          Descargar Instalador
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="login-version">
          Versión: {process.env.REACT_APP_VERSION}
        </div>
      </div>
    );
  }
}

LoginConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const Login = connect(
  null,
  mapDispatchToProps
)(LoginConnector);

export default Login;
