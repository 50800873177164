import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import NotificationProvider from "./NotificationProvider";
import 'react-app-polyfill/ie9'; // For IE 9-11 support

ReactDOM.render(
  <NotificationProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </NotificationProvider>,
  document.getElementById("root")
);
registerServiceWorker();
