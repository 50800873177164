export default {
  items: [
    {
      name: "Monitoreo de Tiendas",
      url: "/home",
      icon: "fa fa-line-chart"
    },
    {
      title: true,
      name: "Ver Más",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      name: "Marcas",
      url: "/brands",
      icon: "fa fa-users"
    },
    {
      name: "Tiendas",
      url: "/stores",
      icon: "fa fa-building"
    },
    {
      name: "Configuración",
      url: "/configure",
      icon: "fa fa-building"
    }
  ]
};
