import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ButtonToolbar,
  ButtonGroup,
  Button
} from "reactstrap";
import { AuthService } from "../../services/authService";
import { Link } from "react-router-dom";
import { ModalDelete } from "../../containers";
import ChangePassword from "./ChangePassword";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { connect } from "react-redux";

let authService = new AuthService();

class ViewUserConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      deleting: false,
      deleted: false,
      view: false
    };
    this.loadData();
    this.toogleDelete = this.toogleDelete.bind(this);
    this.toogleView = this.toogleView.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleView() {
    this.setState({
      view: !this.state.view
    });
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    authService.deleteById(this.props.match.params.id).then(function(data) {
      authService.reload();
      self.context.getNotificationSystem().addNotification({
        message: "Se ha eliminado " + self.state.data.name + ".",
        title: "Usuario Eliminado",
        level: "success"
      });
      self.setState({ deleted: true });
      self.toogleDelete();
    });
  }
  loadData() {
    let self = this;
    authService
      .getFullUser(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            data: data
          });
        } else {
          self.setState({
            deleted: true
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const { data, deleting, deleted, view } = this.state;
    if (deleted) return <Redirect to="/users" push={true} />;
    let role = authService.getRoleName(data);
    let allowEdit = authService.allowEdit(this.props.user, data);
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {data.name || "Cargando..."}
                    </CardTitle>
                    <div className="small text-muted">{data.email}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block">
                    <ButtonToolbar
                      className="float-right"
                      aria-label="Toolbar with button groups"
                    >
                      <ButtonGroup className="mr-3" aria-label="First group">
                        {data.role === "WORKER" && (
                          <Button
                            onClick={this.toogleView}
                            className="btn btn-warning btn-sm float-right"
                          >
                            <i className="fa fa-lock" />
                            {view && (
                              <ChangePassword
                                show={view}
                                close={this.toogleView}
                                worker={data}
                              />
                            )}
                          </Button>
                        )}
                        {allowEdit && (
                          <React.Fragment>
                            <Link
                              to={"/users/edit/" + this.props.match.params.id}
                              className="btn btn-outline-info btn-sm"
                            >
                              <i className="fa fa-edit" />
                            </Link>
                            <Button
                              onClick={this.toogleDelete}
                              className="btn btn-danger btn-sm float-right"
                            >
                              <i className="fa fa-trash" />
                              {deleting && (
                                <ModalDelete
                                  show={deleting}
                                  close={this.toogleDelete}
                                  sendDelete={this.sendDelete}
                                  name={data.name}
                                  entityName="un Usuario"
                                />
                              )}
                            </Button>
                          </React.Fragment>
                        )}
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <h6 className="mb-1 col-sm-4">Rut: {data.rut}</h6>
                  <h6 className="mb-1 col-sm-4">Email: {data.email}</h6>
                  <h6 className="mb-1 col-sm-4">
                    Role: {role}{" "}
                    {data.brand && "(" + data.brand.name + ")"}{" "}
                  </h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          {data.toolInstance &&
            data.toolInstance.length > 0 && (
              <Col xs="12" sm="12" lg="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col sm="5">
                        <CardTitle className="mb-0">
                          Herramientas Asignadas
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            )}
        </Row>
      </div>
    );
  }
}

ViewUserConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const ViewUser = connect(
  mapStateToProps,
  null
)(ViewUserConnector);

export default ViewUser;
