import store from "../redux/store/index";
import ReactGA from "react-ga";

export class BaseService {
  constructor(base) {
    this.store = store;
    this.RGA = ReactGA;
    this.name = "Base";
  }
  GA(action, id = null) {
    this.RGA.event({
      category: action,
      action: this.name,
      label: id
    });
  }
  createOrEdit(data, id) {
    if (id) return this.edit(data, id);
    return this.create(data);
  }

  create(data) {
    let self = this;
    return this.post(this.url, JSON.stringify(data)).then(function(response) {
      if (response.hasOwnProperty("error")) return response;
      self.GA("Create", response.id);
      return response;
    });
  }

  edit(data, id) {
    this.GA("Edit", id);
    return this.patch(this.getIdUrl(id), JSON.stringify(data));
  }

  deleteById(id) {
    this.GA("Delete", id);
    return this.delete(this.getIdUrl(id));
  }

  read() {
    return this.get(this.url);
  }

  readByID(id) {
    return this.get(this.getIdUrl(id));
  }
  get(url) {
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(resp => resp.json());
  }

  getNotJson(url) {
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }

  postNotJson(url, body) {
    return fetch(url, {
      method: "POST",
      credentials: "include",
      body: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }

  post(url, body) {
    return fetch(url, {
      method: "POST",
      credentials: "include",
      body: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(resp => resp.json());
  }

  postBeta(url, body) {
    return fetch(url, {
      method: "POST",
      credentials: "include",
      body: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(resp => resp.body);
  }

  patch(url, body) {
    return fetch(url, {
      method: "PATCH",
      credentials: "include",
      body: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(resp => resp.json());
  }

  delete(url) {
    return fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }

  getUrl(filter, count = null) {
    let url = this.url;
    if (count) url += "/count";
    if (filter) {
      if (count) url += "?where=";
      else url += "?filter=";
      return url + JSON.stringify(filter);
    }
    return url;
  }
  getIdUrl(id, filter) {
    if (filter) {
      return this.url + "/" + id + "?filter=" + JSON.stringify(filter);
    }
    return this.url + "/" + id;
  }

  searchId(list, id) {
    return list.find(x => x.id === id);
  }
}

export default BaseService;
