import React, { Component } from "react";
import { Redirect } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { AuthService } from "../../../services/authService";
import PropTypes from "prop-types";

let authService = new AuthService();
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      password2: "",
      redirect: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const { username, email, password, password2 } = this.state;
    if (password !== password2) {
      self.context.getNotificationSystem().addNotification({
        title: "Error en la Contrasela",
        message: "Las Contraseñas Ingresadas no son Iguales",
        level: "error"
      });
      return;
    }
    authService
      .register(username, email, password)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({ redirect: true });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const { username, email, password, password2, redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" push={true} />;
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <form onSubmit={this.handleSubmit}>
                    <h1>Registrate</h1>
                    <p className="text-muted">Crea tu Cuenta</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Nombre de Usuario"
                        id="username"
                        value={username}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Email"
                        id="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Contraseña"
                        id="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Repite la Contraseña"
                        id="password2"
                        value={password2}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <Button color="success" block>
                      Crear Cuenta
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Register.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

export default Register;
