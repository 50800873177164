import React, { Component } from "react";
import { Button } from "reactstrap";
import moment from "moment";

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format("HH:mm"),
      date: moment().format("DD/MM/YYYY")
    };
    this.mounted = true;
    this.reloadTime = this.reloadTime.bind(this);
    this.interval = window.setInterval(this.reloadTime, 60000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  reloadTime() {
    this.setState({
      time: moment().format("HH:mm"),
      date: moment().format("DD/MM/YYYY")
    });
  }
  render() {
    return (
      <Button className="nav-link">
        {this.state.time} <br /> {this.state.date}
      </Button>
    );
  }
}

export default Time;
