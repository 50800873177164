import React, { Component } from "react";
import {
  CardBody,
  ListGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from "reactstrap";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
      data: this.props.data
    };
    this.changeActive = this.changeActive.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { active: 1, data: nextProps.data };
    }
    return null;
  }
  changeActive(index) {
    this.setState({ active: index });
  }
  addActive(value, pages, active) {
    let total = value + active;
    if (total > pages || total < 1) return;
    this.setState({ active: this.state.active + value });
  }
  render() {
    let {
      data,
      ObjectTemplate,
      length,
      Empty,
      loading,
      Load,
      table,
      Thead,
      removePagination,
      bordered,
      striped
    } = this.props;
    if (!Empty) Empty = <div> No se han encontrado coincidencias </div>;
    if (!Load) Load = <div> Cargando... </div>;
    length = length || 10;
    let { active } = this.state;
    let pagination = [];
    let specialPagination = [];
    let pages = Math.ceil(data.length / length);
    if (pages === 0) pages = 1;
    if (pages > 9) {
      if (active !== 1)
        pagination.push(
          <PaginationItem key="0">
            <PaginationLink tag="button" onClick={() => this.changeActive(1)}>
              1
            </PaginationLink>
          </PaginationItem>
        );
      pagination.push(
        <PaginationItem key="1" active={true}>
          <PaginationLink tag="button">{active}</PaginationLink>
        </PaginationItem>
      );
      if (active !== pages)
        pagination.push(
          <PaginationItem key="2">
            <PaginationLink
              tag="button"
              onClick={() => this.changeActive(pages)}
            >
              {pages}
            </PaginationLink>
          </PaginationItem>
        );
      specialPagination.push(
        <PaginationItem key="3">
          <PaginationLink
            tag="button"
            onClick={() => this.addActive(-1, pages, active)}
          >
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </PaginationLink>
        </PaginationItem>
      );
      specialPagination.push(
        <PaginationItem key="4">
          <PaginationLink
            tag="button"
            onClick={() => this.addActive(1, pages, active)}
          >
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </PaginationLink>
        </PaginationItem>
      );
    } else {
      for (let number = 1; number <= pages; number++) {
        pagination.push(
          <PaginationItem key={number} active={number === active}>
            <PaginationLink
              tag="button"
              onClick={() => this.changeActive(number)}
            >
              {number}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    if (table) {
      Load = (
        <tr>
          <td> Cargando... </td>
        </tr>
      );
      Empty = (
        <tr>
          <td>No se han encontrado coincidencias</td>
        </tr>
      );
      return (
        <React.Fragment>
          <Table
            hover
            bordered={typeof bordered === "undefined" ? true : bordered}
            striped={typeof striped === "undefined" ? true : striped}
            responsive
            size="sm"
          >
            <Thead {...this.props} />
            <tbody>
              {data
                .slice(length * (active - 1), length * active)
                .map((e, i) => {
                  return (
                    <ObjectTemplate
                      {...this.props}
                      key={e.id ? e.id : i}
                      item={e}
                      viewButton={this.props.viewButton}
                    />
                  );
                })}
              {data.length === 0 && !loading && Empty}
              {loading && data.length === 0 && Load}
            </tbody>
          </Table>
          {!removePagination && (
            <div className="d-flex justify-content-around mt-2 mb-0">
              <Pagination>{pagination}</Pagination>
              {specialPagination.length > 0 && (
                <Pagination>{specialPagination}</Pagination>
              )}
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <CardBody>
        <ListGroup>
          {data.slice(length * (active - 1), length * active).map((e, i) => {
            return (
              <ObjectTemplate
                {...this.props}
                key={e.id ? e.id : i}
                item={e}
                viewButton={this.props.viewButton}
              />
            );
          })}
          {data.length === 0 && !loading && Empty}
          {loading && data.length === 0 && Load}
        </ListGroup>
        {!removePagination && (
          <div className="d-flex justify-content-around mt-2 mb-0">
            <Pagination>{pagination}</Pagination>
            {specialPagination.length > 0 && (
              <Pagination>{specialPagination}</Pagination>
            )}
          </div>
        )}
      </CardBody>
    );
  }
}

export default Search;
