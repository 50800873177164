import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_WORKER,
  LOGOUT_WORKER,
  RELOAD_USERS,
  RELOAD_COMPANIES,
  R_C_COMPANY,
  RELOAD_STOREROOMS,
  R_C_STOREROOM,
  RELOAD_SUPPLIES,
  R_C_SUPPLY,
  RELOAD_TOOLS,
  R_C_TOOL,
  RELOAD_TOOLINSTANCES,
  R_C_TOOLINSTANCE,
  RELOAD_FAMILIES,
  R_C_FAMILY,
  RELOAD_TYPES,
  R_C_TYPE,
  RELOAD_REQUESTS,
  R_C_REQUEST,
  RELOAD_INVENTORIES,
  R_C_INVENTORY,
  RELOAD_HOME,
  RELOAD_CURRENT_INVENTORY,
  RELOAD_CURRENT_ORDER,
  ADD_SELECTEDREQUEST,
  REMOVE_SELECTEDREQUEST,
  RELOAD_PROVIDERS,
  RELOAD_COST_CENTER,
  RELOAD_SERVICE_ORDER,
  TOOGLE_SELECTEDORDER,
  RELOAD_CURRENT_RETURN,
  ADD_DASHBOARD_VALUE,
  RELOAD_NOTIFICATION,
  RELOAD_CONTRACTORS,
  RELOAD_DEVICES,
  RELOAD_CHANNELS,
  RELOAD_STORES,
  R_C_STORE,
  RELOAD_ZONES,
  RELOAD_PUBLIC_LINKS,
  RELOAD_ERROR_SONGS
} from "../constants/action-types";
const initialState = {
  user: {},
  users: [],
  brands: [],
  brand: {},
  companies: [],
  company: {},
  home: [],
  devices: [],
  channels: [],
  stores: [],
  store: {},
  zones: [],
  publicLinks: [],
  errorSongs: []
};
const removeFromObject = (obj, id) => {
  let { [id]: omit, ...res } = obj;
  return res;
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DASHBOARD_VALUE:
      return {
        ...state,
        dashboard: Object.assign({}, state.dashboard, {
          [action.payload.id]: action.payload
        })
      };
    case LOGIN_USER:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return { ...state, user: {} };
    case LOGIN_WORKER:
      return {
        ...state,
        worker: action.payload,
        selectedOrders: [],
        currentReturn: []
      };
    case LOGOUT_WORKER:
      return { ...state, worker: null, selectedOrders: [], currentReturn: [] };
    case RELOAD_USERS:
      return { ...state, users: action.payload };
    case RELOAD_COMPANIES:
      return { ...state, companies: action.payload };
    case R_C_COMPANY:
      return { ...state, company: action.payload };
    case RELOAD_STOREROOMS:
      return { ...state, brands: action.payload };
    case R_C_STOREROOM:
      return { ...state, brand: action.payload };
    case RELOAD_SUPPLIES:
      return { ...state, supplies: action.payload };
    case R_C_SUPPLY:
      return { ...state, supply: action.payload };
    case RELOAD_TOOLS:
      return { ...state, tools: action.payload };
    case R_C_TOOL:
      return { ...state, tool: action.payload };
    case RELOAD_TOOLINSTANCES:
      return { ...state, toolInstances: action.payload };
    case R_C_TOOLINSTANCE:
      return { ...state, toolInstance: action.payload };
    case RELOAD_FAMILIES:
      return { ...state, families: action.payload };
    case R_C_FAMILY:
      return { ...state, family: action.payload };
    case RELOAD_TYPES:
      return { ...state, types: action.payload };
    case RELOAD_PROVIDERS:
      return { ...state, providers: action.payload };
    case RELOAD_CONTRACTORS:
      return { ...state, contractors: action.payload };
    case RELOAD_COST_CENTER:
      return { ...state, costCenters: action.payload };
    case RELOAD_SERVICE_ORDER:
      return { ...state, serviceOrders: action.payload };
    case R_C_TYPE:
      return { ...state, type: action.payload };
    case RELOAD_REQUESTS:
      return { ...state, requests: action.payload, selectedRequests: {} };
    case R_C_REQUEST:
      return {
        ...state,
        request: Object.assign({}, state.request, {
          [action.payload.id]: action.payload
        })
      };
    case ADD_SELECTEDREQUEST:
      return {
        ...state,
        selectedRequests: Object.assign({}, state.selectedRequests, {
          [action.payload.id]: action.payload
        })
      };
    case REMOVE_SELECTEDREQUEST:
      return {
        ...state,
        selectedRequests: removeFromObject(
          state.selectedRequests,
          action.payload.id
        )
      };
    case TOOGLE_SELECTEDORDER:
      return {
        ...state,
        currentReturn: state.currentReturn.find(x => x.id === action.payload.id)
          ? state.currentReturn.filter(
            x => (x.id === action.payload.id ? false : true)
          )
          : [...state.currentReturn, action.payload]
      };
    case RELOAD_INVENTORIES:
      return { ...state, inventories: [...action.payload] };
    case R_C_INVENTORY:
      return { ...state, inventory: [...action.payload] };
    case RELOAD_CURRENT_INVENTORY:
      return { ...state, currentInventory: [...action.payload] };
    case RELOAD_CURRENT_ORDER:
      return { ...state, currentOrder: [...action.payload] };
    case RELOAD_HOME:
      return { ...state, home: action.payload };
    case RELOAD_CURRENT_RETURN:
      return { ...state, currentReturn: [...action.payload] };
    case RELOAD_NOTIFICATION:
      return {
        ...state,
        notification: Object.assign({}, state.notification, {
          [action.payload.id]: action.payload.value
        })
      };
    case RELOAD_DEVICES:
      return { ...state, devices: [...action.payload] };
    case RELOAD_CHANNELS:
      return { ...state, channels: [...action.payload] };
    case RELOAD_STORES:
      return { ...state, stores: [...action.payload] };
    case RELOAD_ZONES:
      return { ...state, zones: [...action.payload] };
    case R_C_STORE:
      return { ...state, store: action.payload };
    case RELOAD_PUBLIC_LINKS:
      return { ...state, publicLinks: action.payload };
    case RELOAD_ERROR_SONGS:
      return { ...state, errorSongs: action.payload };

    default:
      return state;
  }
};
export default rootReducer;
