import React, { Component } from "react";
import {
  CardTitle,
  Card,
  CardHeader,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Col,
  Button
} from "reactstrap";
import { ErrorSongService } from "../../services/errorSongService";
import { reloadErrorSongs } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { CompanyService } from "../../services/companyService";
import PropTypes from "prop-types";
import { ChannelService } from "../../services/channelService";
import { BrandService } from "../../services/brandService";
import { ZoneService } from "../../services/zoneService";
import moment from "moment";
import StoreService from '../../services/storeService';
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";


let errorSongService = new ErrorSongService();
let companyService = new CompanyService();
let channelService = new ChannelService();
let brandService = new BrandService();
let storeService = new StoreService();
let zoneService = new ZoneService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Tienda</th>
          <th>Marca</th>
          <th>Cliente</th>
          <th>Zona</th>
          <th>Canción</th>
          <th>Error</th>
          <th>Tiempo Computador</th>
          <th>Tiempo Programado</th>
          <th>Tiempo Sistema</th>
        </tr>
      </thead>
    );
  }
}


class ErrorSong extends Component {
  render() {
    let item = this.props.item;
    let store = storeService.Search(item.storeId)
    return (
      <tr>
        <td>{store.name}</td>
        <td>{brandService.MatchName(store.brandId)}</td>
        <td>{companyService.MatchName(item.companyId)}</td>
        <td>{zoneService.MatchName(store.zoneId)}</td>
        <td>{item.name}</td>
        <td>{item.error}</td>
        <td>
          {item.computerTime
            ? moment(item.computerTime).format("DD/MM/YYYY HH:mm")
            : "-"}
        </td>
        <td>
          {item.rightTime}
        </td>
        <td>
          {item.lastModified
            ? moment(item.lastModified).format("DD/MM/YYYY HH:mm")
            : "-"}
        </td>
      </tr>
    );
  }
}


class ErrorSongConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: "",
      date: new Date()
    };
    channelService.reload();
    companyService.reload();
    brandService.reload();
    storeService.reload();
    zoneService.reload();
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
  }
  handleDayChange(day) {
    let self = this;
    self.setState({ loading: true })
    this.setState({ date: day });
    errorSongService.getWithFilter(
      day
    ).then(function (data) {
      self.props.reloadErrorSongs(data);
      self.setState({
        loading: false
      })
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  deleteAll() {
    let self = this;
    errorSongService.deleteAll().then(
      function (data) {
        self.context.getNotificationSystem().addNotification({
          message:
            "Se han eliminado " + data.count + " registros de errores.",
          level: "success"
        })
      }
    )
  }
  loadData(self) {
    errorSongService
      .getWithFilter(this.state.date)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadErrorSongs(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    let { data, user } = this.props;
    let date = this.state.date;
    let company = this.state.company;
    let filtered = data;
    if (company) {
      filtered = filtered.filter(x => company === x.companyId);
    }
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-0">Tiendas</CardTitle>
              {user.role === "ADMIN" && (
                <h6 className="mb-1">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Compañia</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      id="company"
                      value={company}
                      onChange={this.handleChange}
                    >
                      <option value="">Cualquier Compañia</option>
                      {this.props.companies.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                </h6>
              )}

              <Col sm="4" className="d-sm-inline-block">
                <InputGroup className="mb-2">
                  <DayPickerInput
                    placeholder={moment(date).format("DD/MM/YY")}
                    onDayChange={day => this.handleDayChange(day)}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    inputProps={{ className: "form-control" }}
                    dayPickerProps={{
                      locale: "es",
                      localeUtils: MomentLocaleUtils
                    }}
                  />
                </InputGroup>
              </Col>
              <h6 className="mb-1">
                <div className="card-header-actions">
                  <Button
                    onClick={this.deleteAll}
                    className="btn btn-default btn-sm float-right"
                  >
                    Eliminar datos
                  </Button>
                </div>
              </h6>
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={ErrorSong}
            loading={this.state.loading}
            bordered={false}
            striped={false}
            viewButton={user.role === "ADMIN"}
          />
        </Card>
      </div>
    );
  }
}


ErrorSongConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    data: state.errorSongs,
    companies: state.companies,
    user: state.user,
    channels: state.channels,
    zones: state.zones,
    brands: state.brands,
    stores: state.stores
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadErrorSongs: errorSongs => dispatch(reloadErrorSongs(errorSongs))
  };
};

const ErrorSongs = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorSongConnector);

export default ErrorSongs;
