import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { ZoneService } from "../../services/zoneService";
import { Redirect } from "react-router";
import { CompanyService } from "../../services/companyService";
import { ChannelService } from "../../services/channelService";
import { BrandService } from "../../services/brandService";
import PropTypes from "prop-types";
import { connect } from "react-redux";

let zoneService = new ZoneService();
let companyService = new CompanyService();
let channelService = new ChannelService();
let brandService = new BrandService();

class EditZoneConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      name: "",
      tag: "",
      companyId: "",
      channelId: "",
      brandId: "",
      key: "",
      loading: !creating,
      creating: creating,
      success: false
    };
    companyService.reload();
    channelService.reload();
    brandService.reload();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBrand = this.handleBrand.bind(this);
    this.handleTag = this.handleTag.bind(this);
    this.handleName = this.handleName.bind(this);
    if (!creating) this.loadData();
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleTag(event) {
    this.setState({ tag: event.target.value.replace(/\s/g, "").toUpperCase() });
  }
  handleName(event) {
    this.setState({
      name: event.target.value,
      tag: event.target.value
        .replace(/\s/g, "")
        .substring(0, 4)
        .toUpperCase()
    });
  }
  handleBrand(event) {
    let brand = this.props.brands.find(x => event.target.value === x.id);
    this.setState({ brandId: brand.id, channelId: brand.channelId });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const { name, tag, companyId, channelId, brandId, key } = this.state;
    let req = {
      name: name,
      tag: tag,
      companyId: companyId,
      channelId: channelId,
      brandId: brandId,
      key: key
    };
    if (tag.length !== 4)
      return self.context.getNotificationSystem().addNotification({
        title: "No se pudo crear la Zona",
        message: "El Tag tiene que tener cuatro letras.",
        level: "warning"
      });

    return zoneService
      .createOrEdit(req, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "Se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente la Zona " +
              req.name +
              ".",
            level: "success"
          });
        } else if (data.error.details.codes.tag) {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear la Zona",
            message:
              "Tag ocupado en otra zona. Modifique el nombre de la zona para generar otro Tag",
            level: "error"
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    zoneService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          self.setState(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const {
      creating,
      name,
      tag,
      loading,
      success,
      companyId,
      brandId,
      channelId
    } = this.state;
    let brand = brandService.Match(brandId);
    let key = brand ? brand.key + "-" + tag : "XXXX-XXXX-" + tag;
    if (success) return <Redirect to="/zones" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating ? "Agregando Zona" : "Editando Zona"}
                    </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="name"
                          value={name}
                          onChange={this.handleName}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Tag</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="tag"
                          value={tag}
                          maxlength="4"
                          onChange={this.handleTag}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Key</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="key"
                          value={key}
                          readOnly
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Compañia</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="companyId"
                          value={companyId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona una Compañia</option>
                          {this.props.companies.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Marca</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="brandId"
                          value={brandId}
                          onChange={this.handleBrand}
                        >
                          <option value="">Selecciona una Marca</option>
                          {this.props.brands
                            .filter(
                              x => x.companyId === companyId && x.unlockZones
                            )
                            .map((e, i) => {
                              return (
                                <option key={i} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            })}
                        </Input>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Canal</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="channelId"
                          value={channelId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona un Canal</option>
                          {this.props.channels.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditZoneConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    companies: state.companies,
    user: state.user,
    channels: state.channels,
    brands: state.brands
  };
};

const EditZone = connect(
  mapStateToProps,
  null
)(EditZoneConnector);

export default EditZone;
