import React, { Component } from "react";
import {
  Col,
  Row,
  CardTitle,
  Card,
  CardHeader,
  Button,
  ButtonGroup
} from "reactstrap";
import { CompanyService } from "../../services/companyService";
import { Link } from "react-router-dom";
import { reloadCompanies } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { ModalDelete, UploadPicture } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import ImageLoader from "react-load-image";
const KEYS_TO_FILTERS = ["name", "manager", "email"];

let companyService = new CompanyService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Foto</th>
          <th>Cliente</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      picture: false,
      src: companyService.picture(this.props.item.id)
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
    this.reloadImage = this.reloadImage.bind(this);
    this.tooglePicture = this.tooglePicture.bind(this);
  }
  tooglePicture() {
    this.setState({
      show: !this.state.show
    });
  }
  reloadImage() {
    this.setState({
      show: false,
      src: companyService.picture(this.props.item.id) + "?" + new Date()
    });
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    companyService.deleteById(this.props.item.id).then(function(data) {
      companyService.reload();
      self.context.getNotificationSystem().addNotification({
        message: "Se ha eliminado " + self.props.item.name + ".",
        title: "Compañia Eliminada",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let { deleting, show, src } = this.state;
    let { item } = this.props;
    return (
      <tr>
        <td className="text-center">
          <ImageLoader src={src}>
            <img className="img-fluid img-thumbnail img-profile" alt="avatar" />
            <div>No tiene Imagen</div>
            <div>Cargando...</div>
          </ImageLoader>
        </td>
        <td>{item.name}</td>
        <td>{item.email}</td>
        <td>{item.telephone}</td>
        <td className="text-center">
          <ButtonGroup className="mr-3" aria-label="First group">
            <Button
              onClick={this.toogleDelete}
              className="btn btn-danger btn-sm"
            >
              <i className="fa fa-trash" />{" "}
              {deleting && (
                <ModalDelete
                  show={deleting}
                  close={this.toogleDelete}
                  sendDelete={this.sendDelete}
                  name={
                    this.props.item.name +
                    " junto a todas sus marcas y tiendas asociadas"
                  }
                  entityName="una Compañia"
                />
              )}
            </Button>
            <Button
              onClick={this.tooglePicture}
              className="btn btn-primary btn-sm tooltipButton"
            >
              <i className="fa fa-upload fa-lg" />
              <span className="tooltiptext">Cambiar Foto</span>
              {show && (
                <UploadPicture
                  show={show}
                  close={this.tooglePicture}
                  reload={this.reloadImage}
                  showName={"Compañia: " + item.name}
                  container="company"
                  name={item.id}
                />
              )}
            </Button>
            <Link
              to={"/companies/view/" + this.props.item.id}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye" />
            </Link>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

Company.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class CompanyConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: ""
    };
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  loadData(self) {
    companyService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadCompanies(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let data = this.props.data;
    const filtered = data.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <Row>
              <Col sm="4">
                <CardTitle className="mb-0">Compañias</CardTitle>
              </Col>
              <Col sm="4" className="d-sm-inline-block">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </Col>
              <Col sm="4" className="d-sm-inline-block">
                <div className="card-header-actions">
                  <Link
                    to={"/companies/create"}
                    className="btn btn-default btn-sm float-right"
                  >
                    Agregar Compañia <i className="fa fa-plus" />
                  </Link>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={Company}
            loading={this.state.loading}
            bordered={false}
            striped={false}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state.companies };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadCompanies: companies => dispatch(reloadCompanies(companies))
  };
};

const Companies = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyConnector);

export default Companies;
