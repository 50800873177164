import React, { Component } from "react";
import {
  CardHeader,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { connect } from "react-redux";
import { ContainerService } from "../../services/containerService";
//import AudioPlayer from "react-cl-audio-player";
import AudioPlayer from "../Reproductor/index";

let containerService = new ContainerService();

function removeChannel(title) {
  console.log(title);
  let newTitle = title.split("/");
  return newTitle[newTitle.length - 1].slice(0, -4);
}

class ReproductorConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: "",
      songList: null
    };
    this.mounted = true;
    this.loadData = this.loadData.bind(this);
    this.loadData();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  loadData() {
    let self = this;
    if (this.props.channel)
      containerService
        .getListUrl({ channel: this.props.channel, timezoneOffset: new Date().getTimezoneOffset() })
        .then(function (data) {
          //console.log(data);
          let result = [];
          for (let i in data) {
            if (data[i].title) {
              //console.log(data[i]);
              data[i].artist = { song: removeChannel(data[i].title) + (data[i].time ? ". Hora: " + data[i].time : "") };
              result.push(data[i]);
            }

          }
          self.setState({
            songList: result
          });
        });
  }
  render() {
    if (!this.props.channel)
      return (
        <div className="alert alert-warning" role="alert">
          Esta Marca no tiene Canal
        </div>
      );
    if (this.state.songList && this.state.songList.length > 0)
      return (
        <div className="animated fadeIn">
          <AudioPlayer
            songs={this.state.songList}
          />
          <Card className="player-container mt-2">
            <CardHeader>
              Muestra de Canciones del {this.props.channel}
            </CardHeader>
            <CardBody>
              <ListGroup>
                {this.state.songList.map((x, i) => (
                  <ListGroupItem key={i}>{x.artist.song}</ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      );
    if (!this.state.songList)
      return (
        <div className="alert alert-primary" role="alert">
          Cargando Reproductor
        </div>
      );
    return "Este Canal no tiene Canciones";
  }
}

const mapStateToProps = state => {
  return {};
};

const Reproductor = connect(
  mapStateToProps,
  null
)(ReproductorConnector);

export default Reproductor;
