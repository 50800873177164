import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { CompanyService } from "../../services/companyService";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import "react-select/dist/react-select.css";

let companyService = new CompanyService();

let days = [
  { id: "NONE", value: "Ningun Correo" },
  { id: "DAILY", value: "Diario" },
  { id: "WEEKLY", value: "Semanal" },
  { id: "BYWEEKLY", value: "Quincenal" },
  { id: "MONTHLY", value: "Mensual" }
];

class Email extends Component {
  render() {
    return (
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Email {this.props.number + 1} </InputGroupText>
        </InputGroupAddon>
        <Input
          required
          type="text"
          disabled={this.props.loading}
          value={this.props.email}
          onChange={this.props.handleChange}
        />
        <InputGroupAddon addonType="append">
          <Button color="danger" type="button" onClick={this.props.delete}>
            Eliminar <i className="fa fa-trash" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

class EditConfigConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      telephone: "",
      storeEmailType: "",
      storeEmails: [],
      loading: true,
      success: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeEmails = this.changeEmails.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.changeType = this.changeType.bind(this);
    this.removeEmail = this.removeEmail.bind(this);
    this.loadData();
  }
  changeType(type) {
    console.log(type);
    this.setState({
      storeEmailType: type
    })
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  changeEmails(index, newEmail) {
    let email = newEmail.target.value;
    let storeEmails = this.state.storeEmails.slice();
    storeEmails[index] = email;
    this.setState({
      storeEmails: storeEmails
    });
  }
  addEmail() {
    let storeEmails = this.state.storeEmails.slice();
    storeEmails.push("");
    this.setState({
      storeEmails: storeEmails
    });
  }
  removeEmail(index) {
    let storeEmails = this.state.storeEmails.slice();
    storeEmails.splice(index, 1);
    return this.setState({
      storeEmails: storeEmails
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const {
      id,
      storeEmailType,
      storeEmails
    } = this.state;
    let req = {
      company: {
        id: id,
        storeEmailType: storeEmailType ? storeEmailType.id : "NONE",
        storeEmails: storeEmails
      }
    };
    companyService
      .changeConfig(req)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message: "Se ha cambiado la configuración correctamente.",
            level: "success"
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    companyService
      .readByID(this.props.data.companyId)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          if (data.storeEmailType) {
            let day = days.find(x => String(x.id) === String(data.storeEmailType));
            data.storeEmailType = day;
          }
          self.setState(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const {
      name,
      telephone,
      email,
      loading,
      success,
      storeEmailType,
      storeEmails
    } = this.state;
    if (success) return <Redirect to="/home" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      Configuración
                                        </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" readOnly value={name} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Email</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" readOnly value={email} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Teléfono</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" readOnly value={telephone} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            Elija recurrencia de el correo
                                                    </InputGroupText>
                        </InputGroupAddon>
                        <div className="form-control p-0 m-0 border-0">
                          <Select
                            name="form-field-name"
                            value={storeEmailType}
                            onChange={this.changeType}
                            isLoading={loading}
                            options={days}
                            placeholder=""
                            noResultsText="No hay resultados"
                            valueKey="id"
                            labelKey="value"
                          />
                        </div>
                      </InputGroup>

                      <div className="clearfix " />
                      {storeEmails.map((e, i) => {
                        return (
                          <Email
                            key={i}
                            email={e}
                            number={i}
                            loading={loading}
                            handleChange={event =>
                              this.changeEmails(i, event)
                            }
                            delete={() => this.removeEmail(i)}
                          />
                        );
                      })}
                      <Button
                        color="secondary"
                        type="button"
                        className="float-right mb-3 btn-block btn-sm"
                        onClick={this.addEmail}
                      >
                        Agregar Email
                                                    </Button>

                      <Button color="success" className="float-right">
                        Editar
                                            </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditConfigConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { data: state.user };
};

const EditConfig = connect(
  mapStateToProps,
  null
)(EditConfigConnector);

export default EditConfig;
