import BaseService from "./baseService";
import { reloadBrands } from "../redux/actions/index";

export class BrandService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Brands";
    this.name = "Brands";
  }
  picture(brand) {
    return "/api/containers/brand/download/" + brand;
  }
  getFullBrand(id) {
    let filter = {
      include: ["channel", "stores"]
    };
    return this.get(this.getIdUrl(id, filter));
  }
  Match(id){
    let answer = this.searchId(this.store.getState().brands, id);
    return answer
  }
  MatchName(id) {
    let answer = this.searchId(this.store.getState().brands, id);
    return answer ? answer.name : "-";
  }
  getCompanyBrand(id) {
    let filter = {
      where: {
        companyId: id
      }
    };
    return this.get(this.getUrl(filter));
  }
  reload() {
    let self = this;
    return this.read().then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadBrands(data));
      }
    });
  }
}

export default BrandService;
