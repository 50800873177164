import React, { Component } from "react";
import { Redirect } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { AuthService } from "../../../services/authService";
import PropTypes from "prop-types";

let authService = new AuthService();
class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const { password, password2 } = this.state;
    if (password !== password2) {
      self.context.getNotificationSystem().addNotification({
        title: "Error en la Contrasela",
        message: "Las Contraseñas Ingresadas no son Iguales",
        level: "error"
      });
      return;
    }
    authService
      .changePassword(password, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.context.getNotificationSystem().addNotification({
            title: "Contraseña Cambiada",
            message: "Ingrese con sus nuevas credenciales",
            level: "success"
          });
          self.setState({ redirect: true });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const { password, password2, redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" push={true} />;
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <form onSubmit={this.handleSubmit}>
                    <h1>Recuperacion de Contraseña</h1>
                    <p className="text-muted">Ingresa tu nueva contraseña</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Contraseña"
                        id="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Repite la Contraseña"
                        id="password2"
                        value={password2}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <Button color="success" block>
                      Cambiar Contraseña
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Password.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

export default Password;
