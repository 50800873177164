import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { loginUser } from "./redux/actions/index";
import { connect } from "react-redux";
import { AuthService } from "./services/authService";
import GetNav from "./_nav";
import GetRoutes from "./routes";
import PropTypes from "prop-types";
import "./App.css";
// Styles
// Import Flag Icons Set
//import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";
// import '../node_modules/@coreui/styles/scss/_dropdown-menu-right.scss';

// Containers
import { Full } from "./containers";
// Pages
import {
  Login,
  Page404,
  Page500,
  Register,
  Password,
  PasswordToken
} from "./views/Pages";
import FirstLogin from "./views/Pages/FirstLogin"
import PublicLinkView from "./views/PublicLink/ViewPublicLink";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

let page = null;

const logPageView = () => {
  if (page === window.location.pathname) return null;
  page = window.location.pathname;
  ReactGA.set({ page: page });
  ReactGA.pageview(page);
  return null;
};

// import { renderRoutes } from 'react-router-config';

let authService = new AuthService();

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: user => dispatch(loginUser(user))
  };
};

class AppLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentWillMount() {
    let self = this;
    authService
      .getData()
      .then(function (data) {
        if (Object.keys(data).length !== 0) {
          self.props.loginUser(data);
          self.setState({
            loading: false
          });
        } else
          self.setState({
            loading: false
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    if (this.props.user.role === "WORKER") {
      authService.logout();
      return null;
    }
    if (this.state.loading) {
      return null;
    }
    if (Object.keys(this.props.user).length !== 0) {
      let user = this.props.user;
      let firstLogin = this.props.user.firstLogin;
      let navigation = GetNav(user.role);
      let routes = GetRoutes(user.role);
      if (!firstLogin) {
        return (
          <BrowserRouter>
            <React.Fragment>
              <Route component={logPageView} />
              <Switch>
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Route
                  exact
                  path="/public/channel/:id"
                  name="Link publico de Canal"
                  component={PublicLinkView}
                />
                <Route
                  path="/"
                  name="Home"
                  render={routeProps => (
                    <Full
                      {...routeProps}
                      navigation={navigation}
                      routes={routes}
                    />
                  )}
                />
              </Switch>
            </React.Fragment>
          </BrowserRouter>
        );
      }
      return (
        <BrowserRouter>
          <React.Fragment>
            <Route component={logPageView} />
            <Switch>
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/" name="First Login Page" component={FirstLogin} />
            </Switch>
          </React.Fragment>
        </BrowserRouter>
      );
    }
    return (
      <BrowserRouter>
        <React.Fragment>
          <Route component={logPageView} />
          <Switch>
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route
              exact
              path="/password"
              name="Password Recovery"
              component={Password}
            />
            <Route
              exact
              path="/password/:id"
              name="Password Recovery"
              component={PasswordToken}
            />
            <Route
              exact
              path="/public/channel/:id"
              name="Link publico de Canal"
              component={PublicLinkView}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Login Page" component={Login} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

AppLogin.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLogin);

export default App;
