import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { ChannelService } from "../../services/channelService";
import { Redirect } from "react-router";
import { CompanyService } from "../../services/companyService";
import PropTypes from "prop-types";
import { connect } from "react-redux";

let channelService = new ChannelService();
let companyService = new CompanyService();

class EditChannelConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      name: "",
      serverName: "",
      description: "",
      mood: "",
      artists: "",
      curator: "",
      quality: "",
      loading: !creating,
      creating: creating,
      success: false
    };
    companyService.reload();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (!creating) this.loadData();
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const {
      name,
      description,
      curator,
      serverName,
      mood,
      artists,
      quality
    } = this.state;
    let req = {
      name: name,
      description: description,
      curator: curator,
      serverName: serverName,
      mood: mood,
      artists: artists,
      quality: quality
    };
    channelService
      .createOrEdit(req, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "Se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente un Canal.",
            level: "success"
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    channelService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          self.setState(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const {
      creating,
      name,
      description,
      curator,
      serverName,
      mood,
      artists,
      quality,
      loading,
      success
    } = this.state;
    if (success) return <Redirect to="/channels" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating ? "Agregando Canal" : "Editando Canal"}
                    </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre en el Servidor</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          placeholder="Mantener los mismos espacios"
                          id="serverName"
                          value={serverName}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Descripción</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="description"
                          value={description}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Curador</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="curator"
                          value={curator}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Mood</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="mood"
                          value={mood}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            10 artistas mas rotados
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="artists"
                          value={artists}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Calidad de la Musica</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="44.1 kHz"
                          disabled={loading}
                          id="quality"
                          value={quality}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditChannelConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    companies: state.companies,
    user: state.user
  };
};

const EditChannel = connect(
  mapStateToProps,
  null
)(EditChannelConnector);

export default EditChannel;
