import React, { Component } from "react";
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import { BrandService } from "../../services/brandService";
import ViewBrand from "../Brand/ViewBrand";

let brandService = new BrandService();

class RequestsConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: "",
      company: "",
      songList: null,
      brand: ""
    };
    brandService.reload();
    this.mounted = true;
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let filtered = nextProps.brands;
    let company = prevState.company;
    filtered = filtered.filter(x => x.companyId === company);
    if (nextProps.brands.length === 1) {
      return { brand: nextProps.brands[0].id };
    }
    if (filtered.length === 1) {
      return { brand: filtered[0].id };
    }
    return null;
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  render() {
    let { brand, company } = this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>Seleccione la Marca a Monitorear</CardHeader>
              <CardBody>
                {this.props.user.role === "ADMIN" && (
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Compañia</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      id="company"
                      value={company}
                      onChange={this.handleChange}
                    >
                      <option value="">Cualquier Compañia</option>
                      {this.props.companies.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                )}
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Marca</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    id="brand"
                    value={brand}
                    onChange={this.handleChange}
                  >
                    <option value="">Elegir Marca</option>
                    {this.props.brands
                      .filter(x => x.companyId === company || company === "")
                      .map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Input>
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {brand && <ViewBrand id={brand} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brands: state.brands,
    companies: state.companies,
    user: state.user
  };
};

const Requests = connect(
  mapStateToProps,
  null
)(RequestsConnector);

export default Requests;
