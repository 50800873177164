import React, { Component } from "react";
import {
  CardTitle,
  Card,
  CardHeader,
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { BrandService } from "../../services/brandService";
import { Link } from "react-router-dom";
import { reloadBrands } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { CompanyService } from "../../services/companyService";
import { ModalDelete, UploadPicture } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import { ChannelService } from "../../services/channelService";
import ImageLoader from "react-load-image";
const KEYS_TO_FILTERS = ["name", "manager", "email"];

let brandService = new BrandService();
let companyService = new CompanyService();
let channelService = new ChannelService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Foto</th>
          <th>Marca</th>
          <th>Cliente</th>
          <th>Canal</th>
          <th>Contacto</th>
          <th>Teléfono</th>
          <th>Email</th>
          <th>Key</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      show: false,
      key: false,
      picture: false,
      src: brandService.picture(this.props.item.id)
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
    this.toogleKey = this.toogleKey.bind(this);
    this.reloadImage = this.reloadImage.bind(this);
    this.tooglePicture = this.tooglePicture.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  toogleKey() {
    this.setState({
      key: !this.state.key
    });
  }
  tooglePicture() {
    this.setState({
      show: !this.state.show
    });
  }
  reloadImage() {
    this.setState({
      show: false,
      src: brandService.picture(this.props.item.id) + "?" + new Date()
    });
  }
  sendDelete() {
    let self = this;
    brandService.deleteById(this.props.item.id).then(function(data) {
      brandService.reload();
      self.context.getNotificationSystem().addNotification({
        message:
          "Se ha eliminado exitosamente la Marca: " +
          self.props.item.name +
          ".",
        title: "Marca Eliminada",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let { deleting, show, src, key } = this.state;
    let item = this.props.item;
    return (
      <tr>
        <td className="text-center">
          <ImageLoader src={src}>
            <img className="img-fluid img-thumbnail img-profile" alt="avatar" />
            <div>No tiene Imagen</div>
            <div>Cargando...</div>
          </ImageLoader>
        </td>
        <td>{item.name}</td>
        <td>{companyService.MatchName(item.companyId)}</td>
        <td>{channelService.MatchName(item.channelId)}</td>
        <td>{item.manager}</td>
        <td>{item.telephone}</td>
        <td>{item.email}</td>
        <td>
          {key ? item.key : "*".repeat(9)}
          <Button
            onClick={this.toogleKey}
            className="btn btn-warning btn-sm pull-right ml-2"
          >
            {key ? "Ocultar" : "Mostrar"}
          </Button>
        </td>

        <td className="text-center">
          <ButtonGroup className="mr-3" aria-label="First group">
            {this.props.viewButton && (
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />{" "}
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={
                      this.props.item.name + " y todas sus tiendas asociadas"
                    }
                    entityName="una Marca"
                  />
                )}
              </Button>
            )}

            <Button
              onClick={this.tooglePicture}
              className="btn btn-primary btn-sm tooltipButton"
            >
              <i className="fa fa-upload fa-lg" />
              <span className="tooltiptext">Cambiar Foto</span>
              {show && (
                <UploadPicture
                  show={show}
                  close={this.tooglePicture}
                  reload={this.reloadImage}
                  showName={"Marca: " + item.name}
                  container="brand"
                  name={item.id}
                />
              )}
            </Button>
            <Link
              to={"/brands/view/" + this.props.item.id}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye" />
            </Link>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

Brand.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class BrandConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: "",
      company: this.props.id
    };
    channelService.reload();
    companyService.reload();
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  loadData(self) {
    brandService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadBrands(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let { data, user } = this.props;
    let company = this.state.company;
    let filtered = data;
    if (company) {
      filtered = filtered.filter(x => company === x.companyId);
    }
    filtered = filtered.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-0">Marcas</CardTitle>
              {user.role === "ADMIN" &&
                !this.props.id && (
                  <h6 className="mb-1">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Compañia</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="select"
                        id="company"
                        value={company}
                        onChange={this.handleChange}
                      >
                        <option value="">Cualquier Compañia</option>
                        {this.props.companies.map((e, i) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </InputGroup>
                  </h6>
                )}
              <h6 className="mb-1 col-sm-6">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </h6>
              {user.role === "ADMIN" &&
                !this.props.id && (
                  <h6 className="mb-1">
                    <div className="card-header-actions">
                      <Link
                        to={"/brands/create"}
                        className="btn btn-default btn-sm float-right"
                      >
                        Agregar Marca <i className="fa fa-plus" />
                      </Link>
                    </div>
                  </h6>
                )}
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={Brand}
            loading={this.state.loading}
            bordered={false}
            striped={false}
            viewButton={user.role === "ADMIN"}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.brands,
    companies: state.companies,
    user: state.user,
    channels: state.channels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadBrands: brands => dispatch(reloadBrands(brands))
  };
};

const Brands = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandConnector);

export default Brands;
