import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { AuthService } from "../../services/authService";
import { CompanyService } from "../../services/companyService";
import { BrandService } from "../../services/brandService";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

let authService = new AuthService();
let companyService = new CompanyService();
let brandService = new BrandService();

class EditUserConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      name: "",
      username: "",
      telephone: "",
      email: "",
      password: "",
      role: "CLIENT",
      code: "",
      companyId: "",
      brandId: "",
      loading: !creating,
      creating: creating,
      success: false,
      view: false
    };
    this.getBrands = this.getBrands.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeCC = this.changeCC.bind(this);
    this.changeContractor = this.changeContractor.bind(this);
    this.changePassword = this.changePassword.bind(this);
    companyService.reload();
    brandService.reload();
    if (!creating) this.loadData();
  }

  changePassword() {
    this.setState({
      view: !this.state.view
    });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const {
      name,
      username,
      email,
      password,
      telephone,
      role,
      code,
      companyId,
      brandId,
      costCenter,
      contractor
    } = this.state;
    // if (authService.validateRut(username)) {
    let req = {
      name: name,
      telephone: telephone,
      username: email.toLowerCase(),
      rut: authService.formatRut(username),
      email: email.toLowerCase(),
      companyId: companyId,
      brandId: brandId,
      code: code,
      role: role,
      costCenterId: costCenter ? costCenter.id : null,
      contractorId: contractor ? contractor.id : null
    };
    if (this.state.creating) req.password = password;
    authService
      .createOrEdit(req, this.props.match.params.id)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "El " +
              authService.getRoleName(req) +
              " se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente.",
            title: self.state.creating
              ? "Creacion exitosa"
              : "Edicion exitosa",
            level: "success"
          });
        } else if (data.error.details.codes.username) {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear el Usuario",
            message: "Rut ya existe",
            level: "error"
          });
        } else if (data.error.details.codes.code) {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear el Usuario",
            message: "El código Sap ya existe",
            level: "error"
          });
        } else {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear el Usuario",
            message: "Email no es valido o ya existe",
            level: "error"
          });
        }
      })
      .catch(function (error) {
        if (error === "rut not valid") {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear el Usuario",
            message: "El rut no es valido",
            level: "error"
          });
        } else {
          console.log(error);
        }
      });
    // } else {
    //   self.context.getNotificationSystem().addNotification({
    //     title: "No se pudo crear el Usuario",
    //     message: "El rut no es valido",
    //     level: "error"
    //   });
    // }
  }
  changeCC(costCenter) {
    this.setState({
      costCenter: costCenter
    });
  }
  changeContractor(contractor) {
    this.setState({
      contractor: contractor
    });
  }
  loadData() {
    let self = this;
    authService
      .readByID(this.props.match.params.id)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            name: data.name,
            username: data.username,
            telephone: data.telephone,
            email: data.email,
            role: data.role ? data.role : "WORKER",
            companyId: data.companyId,
            brandId: data.brandId,
            oldUser: data,
            costCenter: data.costCenter,
            contractor: data.contractor,
            code: data.code,
            loading: false
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getBrands(companies, companyId) {
    if (companyId === "") return this.props.brands;
    for (let i in companies) {
      if (companies[i].id === companyId) return companies[i].brand;
    }
    return [];
  }
  render() {
    const {
      creating,
      name,
      username,
      email,
      password,
      role,
      loading,
      success,
      oldUser,
      companyId,
      telephone
    } = this.state;
    let user = this.props.user;
    if (success) return <Redirect to="/users" push={true} />;
    let roleOptions = [];

    if (loading === false) {
      roleOptions = authService.getSelectRoles(user, oldUser);
    }
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating ? "Agregando Usuario" : "Editando Usuario"}
                    </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Telefono</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="telephone"
                          value={telephone}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Rut</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="username"
                          value={username}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Email</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Contraseña</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="password"
                          disabled={loading || !creating}
                          id="password"
                          value={password}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Role</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          disabled={loading}
                          id="role"
                          value={role}
                          onChange={this.handleChange}
                        >
                          {roleOptions.map((e, i) => {
                            return (
                              <option
                                key={i}
                                value={e.id}
                                disabled={e.disabled}
                              >
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      {role === "CLIENT" && (
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Compañia</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            type="select"
                            id="companyId"
                            value={companyId}
                            onChange={this.handleChange}
                          >
                            <option value="">Selecciona una Compañia</option>
                            {this.props.companies.map((e, i) => {
                              return (
                                <option key={i} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      )}

                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditUserConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    data: state.users,
    brands: state.brands,
    companies: state.companies,
    user: state.user
  };
};

const EditUser = connect(
  mapStateToProps,
  null
)(EditUserConnector);

export default EditUser;
