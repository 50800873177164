export default {
  items: [
    {
      name: "Inicio",
      url: "/home",
      icon: "fa fa-line-chart"
    },
    {
      title: true,
      name: "Ver Más",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      name: "Facturacion",
      url: "/requests",
      icon: "fa fa-money"
    }
  ]
};
