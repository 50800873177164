import React, { Component } from "react";
import { CardTitle, Card, CardHeader, ButtonGroup, Button } from "reactstrap";
import { PublicLinkService } from "../../services/publicLinkService";
import { Link } from "react-router-dom";
import { reloadPublicLinks } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { ModalDelete } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import { ChannelService } from "../../services/channelService";
import moment from "moment";
const KEYS_TO_FILTERS = ["name"];

let publicLinkService = new PublicLinkService();
let channelService = new ChannelService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Link</th>
          <th>Canal</th>
          <th>Vistas</th>
          <th>Fecha de Termino</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}

let baseUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "") +
  "/public/channel/";

class PublicLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    publicLinkService.deleteById(this.props.item.id).then(function(data) {
      publicLinkService.reload();
      self.context.getNotificationSystem().addNotification({
        message:
          "Se ha eliminado exitosamente el Link Publico: " +
          self.props.item.name +
          ".",
        title: "Link Publico Eliminado",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let { deleting } = this.state;
    let item = this.props.item;
    let href = baseUrl + item.name;
    return (
      <tr>
        <td>
          <a href={href}>{href} </a>
        </td>
        <td>{channelService.MatchName(item.channelId)}</td>
        <td>{item.views}</td>
        <td>{moment(item.availableDate).format("DD/MM/YY HH:mm")}</td>
        <td className="text-center">
          <ButtonGroup className="mr-3" aria-label="First group">
            {this.props.viewButton && (
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />{" "}
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={item.name}
                    entityName="un Link Publico"
                  />
                )}
              </Button>
            )}
            <Link
              to={"/publicLinks/edit/" + this.props.item.id}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-edit" />
            </Link>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

PublicLink.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class PublicLinkConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: ""
    };
    channelService.reload();
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  loadData(self) {
    publicLinkService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadPublicLinks(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let { data, user } = this.props;
    let filtered = data;
    filtered = filtered.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-0">Link Publicos</CardTitle>
              <h6 className="mb-1 col-sm-6">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </h6>
              {user.role === "ADMIN" &&
                !this.props.id && (
                  <h6 className="mb-1">
                    <div className="card-header-actions">
                      <Link
                        to={"/publicLinks/create"}
                        className="btn btn-default btn-sm float-right"
                      >
                        Agregar Link Publico <i className="fa fa-plus" />
                      </Link>
                    </div>
                  </h6>
                )}
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={PublicLink}
            loading={this.state.loading}
            bordered={false}
            striped={false}
            viewButton={user.role === "ADMIN"}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.publicLinks,
    user: state.user,
    channels: state.channels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadPublicLinks: publicLinks => dispatch(reloadPublicLinks(publicLinks))
  };
};

const PublicLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicLinkConnector);

export default PublicLinks;
