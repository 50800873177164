import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ModalDelete extends Component {
  render() {
    let { show, sendDelete, close, name, entityName } = this.props;
    return (
      <Modal
        isOpen={show}
        toggle={close}
        className={"modal-danger " + this.props.className}
      >
        <ModalHeader toggle={close}>
          ¿Esta seguro de eliminar {entityName}?
        </ModalHeader>
        <ModalBody>
          <p>Esta por eliminar "{name}"</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={sendDelete} className="">
            Eliminar
          </Button>
          <Button color="secondary" onClick={close}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalDelete;
