import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { CompanyService } from "../../services/companyService";

let companyService = new CompanyService();

class ProfileButtonConnector extends Component {
  componentDidMount() {
    companyService.reload();
  }
  render() {
    let user = this.props.user;
    if (user)
      return (
        <Button className="nav-link">
          {user.name} <br /> {companyService.MatchName(user.companyId)}
        </Button>
      );
    return null;
  }
}

const mapStateToProps = state => {
  return { user: state.user, companies: state.companies };
};

const ProfileButton = connect(
  mapStateToProps,
  null
)(ProfileButtonConnector);

export default ProfileButton;
