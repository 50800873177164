import admin from "./navs/admin";
import client from "./navs/client";
import billing from "./navs/billing";

function GetNav(role) {
  switch (role) {
    case "ADMIN":
      return admin;
    case "BILLING":
      return billing;
    case "CLIENT":
      return client;
    default:
      return client;
  }
}

export default GetNav;
