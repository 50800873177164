import BaseService from "./baseService";

export class ContainerService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/containers";
    this.name = "Containers";
  }

  getListUrl(body) {
    console.log(body)
    return this.post(this.url + "/get-list-url", JSON.stringify(body));
  }

  async getFiles(location) {
    let url = this.url + "/" + location + "/files";
    return await this.get(url);
  }
  downloadLink(location, file) {
    return this.url + "/" + location + "/download/" + file;
  }
  createOrReturn(location) {
    let url = this.url;
    let self = this;
    if (location === "") return false;
    url += "/" + location;
    return this.get(url).then(function(result) {
      if (result.hasOwnProperty("error")) {
        return self.post(self.url, JSON.stringify({ name: location }));
      }
      return result;
    });
  }
  upload(file, location, name = null) {
    let self = this;
    let url = this.url + "/" + location + "/upload";
    return this.createOrReturn(location).then(function(container) {
      console.log(container);
      const formData = new FormData();
      formData.append("file", file, name);
      return self.postFile(url, formData);
    });
  }

  postFile(url, body) {
    return fetch(url, {
      method: "POST",
      credentials: "include",
      body: body,
      headers: {
        Accept: "application/json"
      }
    }).then(resp => resp.json());
  }
}

export default ContainerService;
