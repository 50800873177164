
exports.__esModule = true;

let _class, _temp;

let _react = require("react");

let _react2 = _interopRequireDefault(_react);

let _propTypes = require("prop-types");

let _propTypes2 = _interopRequireDefault(_propTypes);

let _classnames = require("classnames");

let _classnames2 = _interopRequireDefault(_classnames);

require("./AudioPlayer.css");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

let AudioPlayer = ((_temp = _class = (function(_PureComponent) {
  _inherits(AudioPlayer, _PureComponent);

  function AudioPlayer(props) {
    _classCallCheck(this, AudioPlayer);

    let _this = _possibleConstructorReturn(
      this,
      _PureComponent.call(this, props)
    );

    _this.shuffle = function(arr) {
      return arr.sort(function() {
        return Math.random() - 0.5;
      });
    };

    _this.updateProgress = function() {
      let _this$audio = _this.audio,
        duration = _this$audio.duration,
        currentTime = _this$audio.currentTime;

      let progress = (currentTime * 100) / duration;

      _this.setState({
        progress: progress
      });
    };

    _this.setProgress = function(e) {
      let target =
        e.target.nodeName === "SPAN" ? e.target.parentNode : e.target;
      let width = target.clientWidth;
      let rect = target.getBoundingClientRect();
      let offsetX = e.clientX - rect.left;
      let duration = _this.audio.duration;
      let currentTime = (duration * offsetX) / width;
      let progress = (currentTime * 100) / duration;

      _this.audio.currentTime = currentTime;

      _this.setState({
        progress: progress
      });

      _this.play();
    };

    _this.play = function() {
      _this.setState({
        playing: true
      });

      _this.audio.play();

      _this.props.onPlay();
    };

    _this.pause = function() {
      _this.setState({
        playing: false
      });

      _this.audio.pause();

      _this.props.onPause();
    };

    _this.toggle = function() {
      return _this.state.playing ? _this.pause() : _this.play();
    };

    _this.next = function() {
      let _this$state = _this.state,
        repeat = _this$state.repeat,
        current = _this$state.current,
        songs = _this$state.songs;

      let total = songs.length;
      let newSongToPlay = repeat
        ? current
        : current < total - 1
          ? current + 1
          : 0;
      let active = songs[newSongToPlay];

      _this.setState({
        current: newSongToPlay,
        active: active,
        progress: 0,
        repeat: false
      });

      _this.audio.src = active.url;
      _this.play();
      _this.props.onNext();
    };

    _this.previous = function() {
      let _this$state2 = _this.state,
        current = _this$state2.current,
        songs = _this$state2.songs;

      let total = songs.length;
      let newSongToPlay = current > 0 ? current - 1 : total - 1;
      let active = songs[newSongToPlay];

      _this.setState({
        current: newSongToPlay,
        active: active,
        progress: 0
      });

      _this.audio.src = active.url;
      _this.play();
      _this.props.onPrevious();
    };

    _this.randomize = function() {
      let _this$state3 = _this.state,
        random = _this$state3.random,
        songs = _this$state3.songs;

      let shuffled = _this.shuffle(songs.slice());

      _this.setState({
        songs: !random ? shuffled : songs,
        random: !random
      });
    };

    _this.repeat = function() {
      return _this.setState({
        repeat: !_this.state.repeat
      });
    };

    _this.toggleMute = function() {
      let mute = _this.state.mute;

      _this.setState({
        mute: !mute
      });

      _this.audio.volume = !!mute;
    };

    _this.state = {
      active: props.songs[0],
      songs: props.songs,
      current: 0,
      progress: 0,
      random: false,
      playing: !!props.autoplay,
      repeat: false,
      mute: false
    };

    _this.audio = document.createElement("audio");
    _this.audio.src = _this.state.active.url;
    _this.audio.autoplay = !!_this.state.autoplay;

    _this.audio.addEventListener("timeupdate", function(e) {
      _this.updateProgress();

      props.onTimeUpdate(e);
    });
    _this.audio.addEventListener("ended", function(e) {
      _this.next();

      props.onEnded(e);
    });
    _this.audio.addEventListener("error", function(e) {
      _this.next();

      props.onError(e);
    });
    return _this;
  }

  AudioPlayer.prototype.componentWillReceiveProps = function componentWillReceiveProps(
    nextProps
  ) {
    this.setState({
      active: nextProps.songs[0],
      songs: nextProps.songs,
      current: 0,
      progress: 0,
      random: false,
      playing: !!nextProps.autoplay,
      repeat: false,
      mute: false
    });
  };

  AudioPlayer.prototype.componentWillUnmount = function componentWillUnmount(

  ) {
    this.audio.pause()
  };

  AudioPlayer.prototype.render = function render() {
    let _this2 = this;

    let _state = this.state,
      currentSong = _state.active,
      progress = _state.progress,
      active = _state.active,
      playing = _state.playing,
      mute = _state.mute,
      random = _state.random,
      repeat = _state.repeat;

    let coverClass = (0, _classnames2.default)({
      "player-cover": true,
      "no-height": !!active.cover === false
    });

    let playPauseClass = (0, _classnames2.default)({
      fa: true,
      "fa-play": !playing,
      "fa-pause": playing
    });

    let volumeClass = (0, _classnames2.default)({
      fa: true,
      "fa-volume-up": !mute,
      "fa-volume-off": mute
    });

    let randomClass = (0, _classnames2.default)({
      "player-btn small random": true,
      active: random
    });

    let repeatClass = (0, _classnames2.default)({
      "player-btn small repeat": true,
      active: repeat
    });

    return _react2.default.createElement(
      "div",
      { className: "player-container" },
      _react2.default.createElement("div", {
        className: coverClass,
        style: { backgroundImage: "url(" + (currentSong.cover || "") + ")" }
      }),
      _react2.default.createElement(
        "div",
        { className: "artist-info" },
        _react2.default.createElement(
          "h2",
          { className: "artist-name" },
          currentSong.artist.name
        ),
        _react2.default.createElement(
          "h3",
          { className: "artist-song-name" },
          currentSong.artist.song
        )
      ),
      _react2.default.createElement(
        "div",
        {
          className: "player-progress-container",
          onClick: function onClick(e) {
            return _this2.setProgress(e);
          }
        },
        _react2.default.createElement("span", {
          className: "player-progress-value",
          style: { width: progress + "%" }
        })
      ),
      _react2.default.createElement(
        "div",
        { className: "player-options" },
        _react2.default.createElement(
          "div",
          { className: "player-buttons player-controls" },
          _react2.default.createElement(
            "button",
            {
              onClick: this.toggle,
              className: "player-btn big",
              title: "Play/Pause"
            },
            _react2.default.createElement("i", { className: playPauseClass })
          ),
          _react2.default.createElement(
            "button",
            {
              onClick: this.previous,
              className: "player-btn medium",
              title: "Previous Song"
            },
            _react2.default.createElement("i", { className: "fa fa-backward" })
          ),
          _react2.default.createElement(
            "button",
            {
              onClick: this.next,
              className: "player-btn medium",
              title: "Next Song"
            },
            _react2.default.createElement("i", { className: "fa fa-forward" })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "player-buttons" },
          _react2.default.createElement(
            "button",
            {
              className: "player-btn small volume",
              onClick: this.toggleMute,
              title: "Mute/Unmute"
            },
            _react2.default.createElement("i", { className: volumeClass })
          ),
          _react2.default.createElement(
            "button",
            {
              className: repeatClass,
              onClick: this.repeat,
              title: "Repeat"
            },
            _react2.default.createElement("i", { className: "fa fa-repeat" })
          ),
          _react2.default.createElement(
            "button",
            {
              className: randomClass,
              onClick: this.randomize,
              title: "Shuffle"
            },
            _react2.default.createElement("i", { className: "fa fa-random" })
          )
        )
      )
    );
  };

  return AudioPlayer;
})(_react.PureComponent)),
(_class.defaultProps = {
  onTimeUpdate: function onTimeUpdate() {},
  onEnded: function onEnded() {},
  onError: function onError() {},
  onPlay: function onPlay() {},
  onPause: function onPause() {},
  onPrevious: function onPrevious() {},
  onNext: function onNext() {}
}),
_temp);
AudioPlayer.propTypes =
  process.env.NODE_ENV !== "production"
    ? {
        songs: _propTypes2.default.array.isRequired,
        autoplay: _propTypes2.default.bool,
        onTimeUpdate: _propTypes2.default.func,
        onEnded: _propTypes2.default.func,
        onError: _propTypes2.default.func,
        onPlay: _propTypes2.default.func,
        onPause: _propTypes2.default.func,
        onPrevious: _propTypes2.default.func,
        onNext: _propTypes2.default.func
      }
    : {};
exports.default = AudioPlayer;
module.exports = exports.default;
