import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ButtonToolbar,
  ButtonGroup,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { loadStore } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { StoreService } from "../../services/storeService";
import { CompanyService } from "../../services/companyService";
import { BrandService } from "../../services/brandService";
import { ChannelService } from "../../services/channelService";
import Reproductor from "../Channel/Reproductor";
import moment from "moment";

let storeService = new StoreService();
let companyService = new CompanyService();
let brandService = new BrandService();
let channelService = new ChannelService();


const MINUTE = 60 * 1000;
const TEN_MINUTES = MINUTE * 10
const HOUR = MINUTE * 60;

class Connection extends Component {
  render() {
    let lastConnection = this.props.lastConnection;
    if (lastConnection) {
      let date = new Date();
      let connectionDate = new Date(lastConnection);
      console.log(date - connectionDate);
      let dif = date - connectionDate;
      if (dif < TEN_MINUTES) return <span className="text-success pull-right">Conectado</span>;
      else if (dif > HOUR)
        return <span className="text-danger pull-right">Desconectado</span>;
      return <span className="text-warning pull-right">Conectado</span>;
    }
    return <span className="text-danger pull-right">-</span>;
  }
}


function getChannel(store) {
  if (store.zone) return store.zone.channelId
  else if (store.brand) return store.brand.channelId
  return null;
}
class ViewStoreConnector extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.loadData();
    channelService.reload();
    brandService.reload();
    companyService.reload();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  loadData() {
    let self = this;
    storeService
      .getFullStore(this.props.match.params.id)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.props.Store(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const { data } = this.props;
    let channel = channelService.Match(getChannel(data));
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="6">
            <Card>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-6">
                    Tienda: {data.name || "Cargando..."}
                  </CardTitle>
                  <Col sm="6" className="d-sm-inline-block">
                    {this.props.user.role === "ADMIN" && (
                      <ButtonToolbar
                        className="float-right"
                        aria-label="Toolbar with button groups"
                      >
                        <ButtonGroup className="mr-3" aria-label="First group">
                          <Link
                            to={"/stores/edit/" + this.props.match.params.id}
                            className="btn btn-outline-info btn-sm"
                          >
                            <i className="fa fa-edit" />
                          </Link>
                        </ButtonGroup>
                      </ButtonToolbar>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12" lg="12">
                    <ListGroup>
                      <ListGroupItem>
                        Cliente:
                        <span className="pull-right">
                          {companyService.MatchName(data.companyId)}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Marca:
                        <span className="pull-right">
                          {brandService.MatchName(data.brandId)}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Canal:
                        <span className="pull-right">{channel.name}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Computador:
                        <span className="pull-right">{data.computerId}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Descripción del Canal:
                        <span className="pull-right">
                          {channel.description}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Artistas del Canal:
                        <span className="pull-right">{channel.artists}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Encargado:
                        <span className="pull-right">{data.manager}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Email:
                        <span className="pull-right">{data.email}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Telefono:
                        <span className="pull-right">{data.telephone}</span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-12">
                    Ultima Canción:
                    <span className="pull-right">{data.songName ? data.songName : "No ha tocado ninguna canción"}</span>
                  </CardTitle>
                </Row>
              </CardHeader>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-12">
                    Ultima Conexión:
                    <span className="pull-right">
                      {data.lastConnection
                        ? moment(data.lastConnection).format("DD/MM/YYYY HH:mm")
                        : "Nunca se ha Conectado"}
                    </span>
                  </CardTitle>
                </Row>
              </CardHeader>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-12">
                    Estado: <Connection lastConnection={data.lastConnection} />
                  </CardTitle>
                </Row>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12" sm="6" lg="6">
            {data.id === this.props.match.params.id && (
              <Reproductor channel={channel && channel.serverName} />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.store,
    user: state.user,
    companies: state.companies,
    channels: state.channels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    Store: store => dispatch(loadStore(store))
  };
};

const ViewStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewStoreConnector);

export default ViewStore;
