import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ContainerService } from "../../services/containerService";

let containerService = new ContainerService();

class UploadPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onChange(e) {
    this.setState({
      file: e.target.files[0],
      filename: e.target.files[0].name,
      image: e.target.files[0]
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    const canvasScaled = this.editor.getImageScaledToCanvas();
    let file = this.dataURLtoBlob(canvasScaled.toDataURL());
    containerService
      .upload(file, this.props.container, this.props.name)
      .then(function(err, data) {
        self.props.reload();
      });
  }
  dataURLtoBlob(dataURL) {
    let binary = atob(dataURL.split(",")[1]);
    let array = [];
    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" });
  }
  selectFile() {
    document.getElementById("uploadFile").click();
  }
  setEditorRef = editor => (this.editor = editor);
  render() {
    let size = 100;
    if (window.innerWidth >= 900) {
      size = 150;
    }
    let { close, show, showName } = this.props;
    return (
      <Modal
        isOpen={show}
        toggle={close}
        className={"modal-primary modal-lg " + this.props.className}
      >
        <form onSubmit={this.handleSubmit}>
          <ModalHeader toggle={close}>
            Cambiar Foto de {showName}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="clear-float text-center">
                  <AvatarEditor
                    ref={this.setEditorRef}
                    image={this.state.image}
                    width={size * 3}
                    height={size * 3}
                    border={size / 5}
                    color={[255, 255, 255, 0.6]}
                    scale={1.2}
                    rotate={0}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.image ? (
              <Button
                type="button"
                className="btn-outline-warning pull-left"
                onClick={this.selectFile}
              >
                Subir Otra Foto
              </Button>
            ) : (
              <Button
                type="button"
                className="btn-outline-success pull-left"
                onClick={this.selectFile}
              >
                Seleccionar Foto
              </Button>
            )}
            {this.state.image && (
              <Button color="success" type="submit" className="">
                Guardar
              </Button>
            )}
            <input
              type="file"
              id="uploadFile"
              className="d-none"
              onChange={this.onChange}
            />
            <Button color="secondary" onClick={close}>
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default UploadPicture;
