import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem
} from "reactstrap";
import PropTypes from "prop-types";
import ProfileButton from "./ProfileButton";
import ChangePassword from "../../views/User/ChangePassword"
import Time from "./Time";
import ImageLoader from "react-load-image";
import { CompanyService } from "../../services/companyService";
import {
  AppHeaderDropdown,
  AppSidebarToggler
} from "@coreui/react";
import { connect } from "react-redux";

import { AuthService } from "../../services/authService";

let companyService = new CompanyService();

let authService = new AuthService();

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class FullHeaderConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false
    }
    this.logout = this.logout.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }
  changePassword() {
    this.setState({
      view: !this.state.view
    });
  }
  logout() {
    authService.logout();
  }
  render() {
    // eslint-disable-next-line
    let view = this.state.view;
    // const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <a
          className="navbar-brand"
          href="/#"
        >
          <ImageLoader src={companyService.picture(this.props.user.companyId)}>
            <img
              src="/assets/img/brand/logo-r.png"
              width="40"
              height="40"
              alt="Acroventus"
              className="navbar-brand-full"
            />
            <img
              src="/assets/img/brand/logo-r.png"
              width="40"
              height="40"
              alt="Acroventus"
              className="navbar-brand-full"
            />
            <div />
          </ImageLoader>
          <ImageLoader src={companyService.picture(this.props.user.companyId)}>
            <img
              src="/assets/img/brand/logo-r.png"
              width="40"
              height="40"
              alt="Acroventus"
              className="navbar-brand-minimized"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/img/brand/logo-r.png"}
              width="40"
              height="40"
              alt="Acroventus"
              className="navbar-brand-minimized"
            />
            <div />
          </ImageLoader>
        </a>
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3" />
        </Nav>

        <Nav className="ml-auto" navbar>
          <NavItem className="d-sm-down-none">
            <ProfileButton />
          </NavItem>
          <NavItem className="d-sm-down-none">
            <Time />
          </NavItem>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-icon" />
              </button>
            </DropdownToggle>
            <DropdownMenu right style={{ right: "auto" }}>
              <DropdownItem onClick={this.changePassword}>
                <i className="fa fa-key" /> Cambiar Contraseña
                {view && (<ChangePassword show={view} user={this.props.user} close={this.changePassword} />)}
              </DropdownItem>
              <DropdownItem onClick={this.logout}>
                <i className="fa fa-lock" /> Cerrar Sesión
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

FullHeaderConnector.propTypes = propTypes;
FullHeaderConnector.defaultProps = defaultProps;

const mapStateToProps = state => {
  return { user: state.user, companies: state.companies };
};

const FullHeader = connect(
  mapStateToProps,
  null
)(FullHeaderConnector);

export default FullHeader;
