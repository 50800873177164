import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Col
} from "reactstrap";
import { AuthService } from "../../services/authService";
import PropTypes from "prop-types";

let authService = new AuthService();

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      oldPassword: ""
    };
    this.changePassword = this.changePassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  changePassword(event) {
    event.preventDefault();
    let self = this;
    const { oldPassword, password, password2 } = this.state;
    if (password !== password2) {
      self.context.getNotificationSystem().addNotification({
        title: "Error en la Contraseña",
        message: "Las Contraseñas Ingresadas no son Iguales",
        level: "error"
      });
      return;
    }
    this.props.close();
    authService.changeOldPassword(oldPassword, password).then(
      function(response){
      console.log(response.status);
      if(response.status === 400){
        self.context.getNotificationSystem().addNotification({
          message:
            "No se pudo cambiar la contraseña , contraseña actual incorrecta",
          title: " Error Cambiando Contraseña",
          level: "error"
        });
        return;
      } else {
        self.context.getNotificationSystem().addNotification({
          message:
            "La contraseña del trabajador " +
            self.props.user.name +
            " fue modificada exitosamente.",
          title: "Cambio de contraseña exitosa",
          level: "success"
        });
      }
    });
    
    return;
    
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  render() {
    let { close, show } = this.props;
    let { password, password2, oldPassword } = this.state;
    return (
      <Modal
        isOpen={show}
        toggle={close}
        className={"modal-primary modal-lg " + this.props.className}
      >
        <form onSubmit={this.changePassword}>
          <ModalHeader toggle={close}>
            Cambiar Contraseña de {this.props.user.name}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <Col>
              <InputGroup className="mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    <i className="icon-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    type="password"
                    placeholder="Ingrese la contraseña actual"
                    id="oldPassword"
                    value={oldPassword}
                    onChange={this.handleChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    type="password"
                    placeholder="Ingrese la nueva contraseña"
                    id="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    type="password"
                    placeholder="Confirma la nueva contraseña"
                    id="password2"
                    value={password2}
                    onChange={this.handleChange}
                  />
                </InputGroup>
              </Col>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" type="submit" className="">
              Cambiar Contraseña
            </Button>
            <Button color="secondary" onClick={close}>
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

ChangePassword.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

export default ChangePassword;
