import Cookies from "js-cookie";
import { logoutUser, reloadUsers } from "../redux/actions/index";
import BaseService from "./baseService";

export class AuthService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/users";
    this.name = "Users";
  }
  readByID(id) {
    let filter = {
      include: []
    };
    return this.get(this.getIdUrl(id, filter));
  }
  login(username, password) {
    let self = this;
    let data = {
      username: username.toLowerCase(),
      password: password
    };
    return this.postNotJson("/auth/local/", JSON.stringify(data)).then(function(
      response
    ) {
      if (response.status === 200) {
        return self.getData();
      } else if (response.status === 401) {
        return { error: 401 };
      }
      return { error: true };
    });
  }

  logout() {
    let self = this;
    Cookies.set("accessToken", null);
    Cookies.set("access_token", null);
    this.getNotJson("/auth/logout").then(function() {
      self.getData().then(function(response) {
        self.store.dispatch(logoutUser);
      });
    });
  }

  createOrEdit(data, id) {
    if (id) return this.edit(data, id);
    return this.register(data);
  }

  register(data) {
    return this.post("/api/users/", JSON.stringify(data));
  }

  getData() {
    return this.get("/auth/current");
  }

  getFullUser(id) {
    let filter = {
      include: ""
    };
    return this.get(this.getIdUrl(id, filter));
  }
  existSap(sap) {
    return this.store.getState().supplies.find(x => x.sap === sap);
  }
  existRut(rut) {
    return this.store.getState().supplies.find(x => x.username === rut);
  }
  existEmail(email) {
    return this.store.getState().supplies.find(x => x.email === email);
  }
  getRoleName(user) {
    if (user.role === "ADMIN") return "Administrador";
    else if (user.role === "BILLING") return "Facturación";
    else if (user.role === "CLIENT") return "Cliente";
    return "";
  }

  reload() {
    let self = this;
    let filter = {
      include: "costCenter"
    };
    return this.get(this.getUrl(filter)).then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadUsers(data));
      }
    });
  }

  readCompany(id) {
    let filter = {
      where: id
    };
    return this.get(this.getUrl(filter));
  }

  validateRut(rut) {
    if (typeof rut !== "string") {
      return false;
    }
    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
      return false;
    }

    let rutTemp = this.cleanRut(rut);

    let t = parseInt(rutTemp.slice(0, -1), 10);
    let m = 0;
    let s = 1;

    while (t > 0) {
      s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
      t = Math.floor(t / 10);
    }

    let v = s > 0 ? "" + (s - 1) : "K";
    return v === rutTemp.slice(-1);
  }

  cleanRut(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  formatRut(rutIn) {
    let rut = this.cleanRut(rutIn);

    let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }

    return result;
  }
  allowEdit(user, oldUser) {
    let roles = this.getSelectRoles(user);
    for (let i in roles) {
      if (roles[i].id === oldUser.role) return true;
    }
    return false;
  }

  allowDelete(roles, userRole) {
    for (let i in roles) {
      if (roles[i].id === userRole) return false;
    }
    return true;
  }

  getSelectRoles(user) {
    return [
      { id: "CLIENT", name: "Cliente", disabled: false },
      { id: "BILLING", name: "Facturación", disabled: false },
      { id: "ADMIN", name: "Administrador", disabled: false }
    ];
  }

  loginWorker(data) {
    const url = "/api/users/login?include=user";
    return this.post(url, data);
  }

  recovery(email) {
    let data = { email: email.toLowerCase() };
    const url = "/request-password-reset";
    return this.post(url, JSON.stringify(data));
  }

  changePassword(password, token) {
    let data = { newPassword: password };
    const url = "/api/users/reset-password?access_token=" + token;
    return this.postNotJson(url, JSON.stringify(data));
  }

  changeOldPassword(oldPassword, newPassword){
    let data = { oldPassword: oldPassword, newPassword: newPassword};
    const url ="/api/users/change-password";
    return this.postNotJson(url, JSON.stringify(data));
  }

  getCredentials(id, fk){
    const url = "/api/users" + id + "/credentials" + fk;
    return this.get(url);
  }


  changeWorkerPassword(worker, password) {
    let data = { password: password, workerId: worker.id };
    const url = "/api/users/change-worker-password";
    return this.post(url, JSON.stringify(data));
  }
}

export default AuthService;
