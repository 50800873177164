import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ButtonToolbar,
  ButtonGroup,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { loadBrand } from "../../redux/actions/index";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { StoreService } from "../../services/storeService";
import { BrandService } from "../../services/brandService";
import { ZoneService } from "../../services/zoneService";
import { ModalDelete } from "../../containers";
import { Search } from "../../containers";
import moment from "moment";

let brandService = new BrandService();
let storeService = new StoreService();
let zoneService = new ZoneService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Tienda</th>
          <th>Zona</th>
          {this.props.viewButton && (
            <React.Fragment>
              <th>Contacto</th>
              <th>Teléfono</th>
              <th>Email</th>
            </React.Fragment>
          )}
          <th>Computador</th>
          <th>Ultima Canción</th>
          <th>Tiempo del Computador</th>
          <th>Ultima Conexión</th>
          <th>Estado</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}
const MINUTE = 60 * 1000;
const TEN_MINUTES = MINUTE * 10
const HOUR = MINUTE * 60;

class Connection extends Component {
  render() {
    let lastConnection = this.props.lastConnection;
    if (lastConnection) {
      let date = new Date();
      let connectionDate = new Date(lastConnection);
      console.log(date - connectionDate);
      let dif = date - connectionDate;
      if (dif < TEN_MINUTES) return <td className="text-success">Conectado</td>;
      else if (dif > HOUR)
        return <td className="text-danger">Desconectado</td>;
      return <td className="text-warning">Conectado</td>;
    }
    return <td className="text-danger">-</td>;
  }
}

class Store extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    storeService.deleteById(this.props.item.id).then(function (data) {
      self.props.reload(false);
      self.context.getNotificationSystem().addNotification({
        message:
          "Se ha eliminado exitosamente la Tienda: " +
          self.props.item.name +
          ".",
        title: "Tienda Eliminada",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let deleting = this.state.deleting;
    let item = this.props.item;
    return (
      <tr>
        <td>{item.name}</td>

        <td>{zoneService.MatchName(item.zoneId)}</td>
        {this.props.viewButton && (
          <React.Fragment>
            <td>{item.manager}</td>
            <td>{item.telephone}</td>
            <td>{item.email}</td>
          </React.Fragment>
        )}
        <td>{item.computerId}</td>
        <td>
          {item.songName ? item.songName : "No ha tocado ninguna canción"}
        </td>
        <td>
          {item.computerTime
            ? moment(item.computerTime).format("DD/MM/YYYY HH:mm")
            : "-"}
        </td>
        <td>
          {item.lastConnection
            ? moment(item.lastConnection).format("DD/MM/YYYY HH:mm")
            : "Nunca se ha Conectado"}
        </td>
        <Connection lastConnection={item.lastConnection} />

        <td className="text-center">
          <ButtonGroup className="mr-3" aria-label="First group">
            {this.props.viewButton && (
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={item.name + " y todas sus tiendas asociadas"}
                    entityName="un Tienda"
                  />
                )}
              </Button>
            )}
            <Link
              to={"/stores/view/" + item.id}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye" />
            </Link>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

Store.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class ViewBrandConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id ? this.props.id : this.props.match.params.id,
      update: false,
      show: false
    };
    this.mounted = true;
    this.loadData = this.loadData.bind(this);
    this.loadData();
    zoneService.reload();
    this.toogleShow = this.toogleShow.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id && nextProps.id !== prevState.id) {
      return { update: true, id: nextProps.id };
    }
    return { update: false };
  }
  toogleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.update === true) {
      this.loadData();
    }
  }
  loadData(deleteFirst = true) {
    let self = this;
    if (deleteFirst) self.props.Brand({});
    console.log(self);
    brandService
      .getFullBrand(self.state.id)
      .then(function (data) {
        if (!data.hasOwnProperty("error")) {
          self.props.Brand(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const { data } = this.props;
    let show = this.state.show;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-6">
                    Marca: {data.name || "Cargando..."}
                  </CardTitle>
                  <Col sm="6" className="d-sm-inline-block">
                    {this.props.user.role === "ADMIN" &&
                      data.id && (
                        <ButtonToolbar
                          className="float-right"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            className="mr-3"
                            aria-label="First group"
                          >
                            <Link
                              to={"/brands/edit/" + data.id}
                              className="btn btn-outline-info btn-sm"
                            >
                              <i className="fa fa-edit" />
                            </Link>
                          </ButtonGroup>
                        </ButtonToolbar>
                      )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="6" lg="6">
                    <ListGroup>
                      <ListGroupItem>
                        Encargado:
                        <span className="pull-right">{data.manager}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Email:
                        <span className="pull-right">{data.email}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Cantidad de Conexiones:
                        <span className="pull-right">
                          {data.availableConnections}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col xs="12" sm="6" lg="6">
                    <ListGroup>
                      <ListGroupItem>
                        Telefono:
                        <span className="pull-right">{data.telephone}</span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Key
                        <span className="pull-right">
                          {show ? data.key : "*".repeat(9)}
                          <Button
                            onClick={this.toogleShow}
                            className="btn btn-warning btn-sm pull-right ml-2"
                          >
                            {show ? "Ocultar" : "Mostrar"}
                          </Button>
                        </span>
                      </ListGroupItem>
                      <ListGroupItem>
                        Conexiones Ocupadas:
                        <span className="pull-right">
                          {
                            (data.stores
                              ? data.stores.filter(x => !x.deleted)
                              : []
                            ).length
                          }
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <CardTitle className="mb-0 col-sm-6">Tiendas</CardTitle>
                </Row>
              </CardHeader>
              <Search
                table={true}
                Thead={TableHead}
                data={data.stores ? data.stores.filter(x => !x.deleted) : []}
                ObjectTemplate={Store}
                bordered={false}
                striped={false}
                viewButton={this.props.user.role === "ADMIN"}
                reload={this.loadData}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state.brand, user: state.user, zones: state.zones };
};

const mapDispatchToProps = dispatch => {
  return {
    Brand: brand => dispatch(loadBrand(brand))
  };
};

const ViewBrand = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewBrandConnector);

export default ViewBrand;
