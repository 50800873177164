export default {
  items: [
    {
      name: "Monitoreo de Tiendas",
      url: "/home",
      icon: "fa fa-line-chart"
    },
    {
      title: true,
      name: "Ver Más",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      name: "Canales",
      url: "/channels",
      icon: "fa fa-building"
    },
    {
      name: "Link Publicos",
      url: "/publiclinks",
      icon: "fa fa-link"
    },
    {
      name: "Compañias",
      url: "/companies",
      icon: "fa fa-building"
    },
    {
      name: "Marcas",
      url: "/brands",
      icon: "fa fa-building"
    },
    {
      name: "Zonas",
      url: "/zones",
      icon: "fa fa-building"
    },
    {
      name: "Tiendas",
      url: "/stores",
      icon: "fa fa-building"
    },
    {
      name: "Usuarios",
      url: "/users",
      icon: "fa fa-users"
    },
    {
      name: "Dispositivos conectados",
      url: "/devices",
      icon: "fa fa-mobile"
    },
    {
      name: "Errores",
      url: "/errorSongs",
      icon: "fa fa-exclamation-triangle"
    }
  ]
};
