import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Alert
} from "reactstrap";
import { connect } from "react-redux";

class WorkerBlockedConnector extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            Estimado {this.props.user.name}
            </CardHeader>
          <CardBody>
            <Alert color="danger">
              Usted es trabajador, no puede entrar a esta aplicación.
            </Alert>
          </CardBody>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const WorkerBlocked = connect(
  mapStateToProps,
  null
)(WorkerBlockedConnector);


export default WorkerBlocked;
