import BaseService from "./baseService";
import { reloadZones } from "../redux/actions/index";

export class ZoneService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/zones";
    this.name = "Zones";
  }
  MatchName(id) {
    let answer = this.searchId(this.store.getState().zones, id);
    return answer ? answer.name : "-";
  }
  getFullStore(id){
    let filter = {
      include: ["channel"]
    };
    return this.get(this.getIdUrl(id, filter));
  }
  reload() {
    let self = this;
    return this.read().then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadZones(data));
      }
    });
  }
}

export default ZoneService;
