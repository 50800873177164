import BaseService from "./baseService";
import { reloadDevices } from "../redux/actions/index";

export class DeviceService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Devices";
    this.name = "Devices";
  }
  reload() {
    let self = this;
    let filter = {
      include: [
        {
          relation: "deviceConnection",
          scope: {
            order: "syncDate DESC",
            limit: 1,
            include: "user"
          }
        },
        "brand"
      ]
    };
    return this.get(this.getUrl(filter)).then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadDevices(data));
      }
    });
  }
}

export default DeviceService;
