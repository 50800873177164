import React, { Component } from "react";
import { CardTitle, Card, CardHeader, ButtonGroup, Button } from "reactstrap";
import { ChannelService } from "../../services/channelService";
import { Link } from "react-router-dom";
import { reloadChannels } from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { ModalDelete } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
const KEYS_TO_FILTERS = ["place", "manager", "email"];

let channelService = new ChannelService();

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Descripción</th>
          <th>Mood</th>
          <th>Artistas</th>
          <th>Calidad</th>
          <th>Curador</th>
          <th />
        </tr>
      </thead>
    );
  }
}

class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    channelService.deleteById(this.props.item.id).then(function(data) {
      channelService.reload();
      self.context.getNotificationSystem().addNotification({
        message:
          "Se ha eliminado exitosamente el Canal: " +
          self.props.item.name +
          ".",
        title: "Canal Eliminado",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let deleting = this.state.deleting;
    let item = this.props.item;
    return (
      <tr>
        <td>
          {item.name}
          <div className="small text-muted">
            Nombre en Servidor: {item.serverName}
          </div>
        </td>
        <td>{item.description}</td>
        <td>{item.mood}</td>
        <td>
          {!item.artists || item.artists.length < 60
            ? item.artists
            : item.artists.substring(0, 60) + "..."}
        </td>
        <td>{item.quality}</td>
        <td>{item.curator}</td>
        {this.props.viewButton && (
          <td className="text-center">
            <ButtonGroup className="mr-3" aria-label="First group">
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />{" "}
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={this.props.item.name}
                    entityName="el Canal"
                  />
                )}
              </Button>
              <Link
                to={"/channels/edit/" + this.props.item.id}
                className="btn btn-primary btn-sm"
              >
                <i className="fa fa-pencil" />
              </Link>
            </ButtonGroup>
          </td>
        )}
      </tr>
    );
  }
}

Channel.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class ChannelConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: ""
    };
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  loadData(self) {
    channelService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadChannels(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let { data, user } = this.props;
    let filtered = data;
    filtered = filtered.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-0">Canales</CardTitle>

              <h6 className="mb-1 col-sm-5">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </h6>
              <h6 className="mb-1">
                <div className="card-header-actions">
                  <Link
                    to={"/channels/create"}
                    className="btn btn-default btn-sm float-right"
                  >
                    Agregar Canal <i className="fa fa-plus" />
                  </Link>
                </div>
              </h6>
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={Channel}
            loading={this.state.loading}
            bordered={false}
            striped={false}
            viewButton={user.role === "ADMIN"}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.channels,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadChannels: channels => dispatch(reloadChannels(channels))
  };
};

const Channels = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelConnector);

export default Channels;
