import React, { Component } from "react";
import { Col, Row, CardTitle, Card, CardHeader } from "reactstrap";
import { DeviceService } from "../../services/deviceService";
import { connect } from "react-redux";
import { Search } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import moment from "moment";
const KEYS_TO_FILTERS = ["brand.name"];

let deviceService = new DeviceService();

class THead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Identificador Dispositivo</th>
          <th>Version</th>
          <th>Modelo</th>
          <th>Sistema Operativo</th>
          <th>Fecha Inicio Sincronizacion</th>
          <th>Termino</th>
        </tr>
      </thead>
    );
  }
}

class Device extends Component {
  render() {
    let item = this.props.item;
    return (
      <tr>
        <td>
          {item.deviceConnection[0] &&
            item.deviceConnection[0].user &&
            item.deviceConnection[0].user.name}
        </td>
        <td>{item.device}</td>
        <td>{item.appVersion}</td>
        <td>{item.model}</td>
        <td>{item.OS}</td>
        <td>
          {item.deviceConnection[0]
            ? moment(item.deviceConnection[0].syncDate).format(
                "DD/MM/YYYY HH:mm"
              )
            : ""}
        </td>
        <td>
          {item.deviceConnection[0] && item.deviceConnection[0].finishDate
            ? moment(item.deviceConnection[0].finishDate).format("HH:mm")
            : ""}
        </td>
      </tr>
    );
  }
}

Device.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class DeviceConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: ""
    };
    this.mounted = true;
    this.loadData(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  loadData(self) {
    deviceService
      .reload()
      .then(function() {
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    let data = this.props.data;
    const filtered = data.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <Row>
              <Col sm="4">
                <CardTitle className="mb-0">Dispositivos</CardTitle>
              </Col>
              <Col sm="4" className="d-sm-inline-block">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </Col>
            </Row>
          </CardHeader>
          <Search
            Thead={THead}
            table={true}
            data={filtered}
            ObjectTemplate={Device}
            loading={this.state.loading}
            bordered={false}
            striped={false}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state.devices };
};

const Devices = connect(
  mapStateToProps,
  null
)(DeviceConnector);

export default Devices;
