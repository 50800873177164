import React, { Component } from "react";
import {
  CardTitle,
  Card,
  CardHeader,
  ButtonGroup,
  ButtonToolbar,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from "reactstrap";
import { AuthService } from "../../services/authService";
import { BrandService } from "../../services/brandService";
import { CompanyService } from "../../services/companyService";
import { Link } from "react-router-dom";
import {
  reloadUsers,
  reloadBrands,
  reloadCompanies
} from "../../redux/actions/index";
import { connect } from "react-redux";
import { Search } from "../../containers";
import { ModalDelete } from "../../containers";
import SearchInput, { createFilter } from "react-search-input";
import PropTypes from "prop-types";
import moment from "moment";

let authService = new AuthService();
let brandService = new BrandService();
let companyService = new CompanyService();
const KEYS_TO_FILTERS = ["name", "email", "rut"];

class TableHead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Rol</th>
          <th>Cliente</th>
          <th>Fecha Creación</th>
          <th>Ultimo Login</th>
          <th>Acción</th>
        </tr>
      </thead>
    );
  }
}

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false
    };
    this.toogleDelete = this.toogleDelete.bind(this);
    this.sendDelete = this.sendDelete.bind(this);
  }
  toogleDelete() {
    this.setState({
      deleting: !this.state.deleting
    });
  }
  sendDelete() {
    let self = this;
    authService.deleteById(this.props.item.id).then(function(data) {
      self.props.reload();
      self.context.getNotificationSystem().addNotification({
        message: "Se ha eliminado " + self.props.item.name + ".",
        title: "Usuario Eliminado",
        level: "success"
      });
      self.toogleDelete();
    });
  }
  render() {
    let deleting = this.state.deleting;
    let { item } = this.props;
    let role = authService.getRoleName(item);
    return (
      <tr>
        <td>
          {item.name}
          <div className="small text-muted">Rut: {item.rut}</div>
        </td>
        <td>{item.email}</td>
        <td>{item.telephone}</td>
        <td>{role}</td>
        <td>{companyService.MatchName(item.companyId)}</td>
        <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
        <td>
          {item.lastLogin
            ? moment(item.lastLogin).format("DD/MM/YYYY HH:mm")
            : "Nunca"}
        </td>
        <td>
          <ButtonToolbar
            className="float-right"
            aria-label="Toolbar with button groups"
          >
            <ButtonGroup className="mr-3" aria-label="First group">
              <Button
                onClick={this.toogleDelete}
                className="btn btn-danger btn-sm float-right"
                disabled={authService.allowDelete(this.props.roles, item.role)}
              >
                <i className="fa fa-trash" />{" "}
                {deleting && (
                  <ModalDelete
                    show={deleting}
                    close={this.toogleDelete}
                    sendDelete={this.sendDelete}
                    name={
                      item.name +
                      " perteneciente a la Empresa " +
                      companyService.MatchName(item.companyId)
                    }
                    entityName="un Usuario"
                  />
                )}
              </Button>
              <Link
                to={"/users/edit/" + item.id}
                className="btn btn-primary btn-sm float-right"
              >
                <i className="fa fa-edit" />
              </Link>
            </ButtonGroup>
          </ButtonToolbar>
        </td>
      </tr>
    );
  }
}

User.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

class UserConnector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchTerm: "",
      company: ""
    };
    this.mounted = true;
    this.searchUpdated = this.searchUpdated.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadData();
  }
  changeCompany(event) {
    this.setState({
      company: event.target.value
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  loadData() {
    let self = this;
    authService
      .read()
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.reloadUsers(data);
        }
        if (self.mounted)
          self.setState({
            loading: false
          });
      })
      .catch(function(error) {
        console.log(error);
      });
    brandService.reload();
    companyService.reload();
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  render() {
    let { data, user } = this.props;
    let company = this.state.company;
    let filtered = data;
    filtered = data.filter(x => x.companyId === company);
    filtered = filtered.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <div className="d-sm-flex w-100 justify-content-between">
              <CardTitle className="mb-1">Usuarios</CardTitle>

              {user.role === "ADMIN" && (
                <h6 className="mb-1">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Compañia</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      id="company"
                      value={company}
                      onChange={this.changeCompany}
                    >
                      <option value="">Sin Compañia</option>
                      {this.props.companies.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                </h6>
              )}
              <h6 className="mb-1 col-sm-5">
                <SearchInput
                  className="search-input input-group"
                  inputClassName="form-control"
                  placeholder="Buscar..."
                  onChange={this.searchUpdated}
                />
              </h6>
              <h6 className="mb-1">
                <div className="card-header-actions">
                  <Link
                    to={"/users/create"}
                    className="btn btn-default btn-sm float-right"
                  >
                    Agregar Usuario <i className="fa fa-plus" />
                  </Link>
                </div>
              </h6>
            </div>
          </CardHeader>
          <Search
            table={true}
            Thead={TableHead}
            data={filtered}
            ObjectTemplate={User}
            loading={this.state.loading}
            roles={authService.getSelectRoles(this.props.user)}
            reload={this.loadData}
            bordered={false}
            striped={false}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.users,
    brands: state.brands,
    companies: state.companies,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reloadUsers: users => dispatch(reloadUsers(users)),
    reloadBrands: brands => dispatch(reloadBrands(brands)),
    reloadCompanies: companies => dispatch(reloadCompanies(companies))
  };
};

const Users = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserConnector);

export default Users;
