import BaseService from "./baseService";
import { reloadErrorSongs } from "../redux/actions/index";

export class ErrorSongService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/ErrorSongs";
    this.name = "ErrorSongs";
  }
  reload() {
    let self = this;
    return this.read().then(function (data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadErrorSongs(data));
      }
    });
  }
  deleteAll() {
    let url = this.url + "/deleteAll";
    return this.post(url)
  }

  getWithFilter(date) {
    let filter = {
      where: {
        and: []
      }
    };
    let fr = new Date(date);
    filter.where.and.push({
      createdAt: {
        gte: new Date(fr.getFullYear(), fr.getMonth(), fr.getDate())
      }
    });

    filter.where.and.push({
      createdAt: {
        lte: new Date(fr.getFullYear(), fr.getMonth(), fr.getDate() + 1)
      }
    });
    return this.get(this.getUrl(filter))
  }
}

export default ErrorSongService;
