import BaseService from "./baseService";
import { reloadStores } from "../redux/actions/index";

export class StoreService extends BaseService {
  constructor(base) {
    super(base);
    this.url = "/api/Stores";
    this.name = "Stores";
  }
  MatchName(id) {
    let answer = this.searchId(this.store.getState().stores, id);
    return answer ? answer.name : "-";
  }
  Search(id) {
    let answer = this.searchId(this.store.getState().stores, id);
    return answer || {};
  }
  getFullStore(id) {
    let filter = {
      include: [{ brand: "channel" }, { zone: "channel" }]
    };
    return this.get(this.getIdUrl(id, filter));
  }
  reload() {
    let self = this;
    return this.read().then(function(data) {
      if (!data.hasOwnProperty("error")) {
        self.store.dispatch(reloadStores(data));
      }
    });
  }
}

export default StoreService;
