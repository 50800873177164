import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class FullFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>Rinoceronte Media</span>
        <span className="ml-auto">
          Todos los Derechos Reservados &copy; 2018 Desarrollado
          por: <a href="https://www.acroventus.cl/">Acroventus Ltda.</a> Versión: {process.env.REACT_APP_VERSION}
        </span>
      </React.Fragment>
    );
  }
}

FullFooter.propTypes = propTypes;
FullFooter.defaultProps = defaultProps;

export default FullFooter;
