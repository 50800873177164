import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import { BrandService } from "../../services/brandService";
import { Redirect } from "react-router";
import { CompanyService } from "../../services/companyService";
import { ChannelService } from "../../services/channelService";
import PropTypes from "prop-types";
import { connect } from "react-redux";

let brandService = new BrandService();
let companyService = new CompanyService();
let channelService = new ChannelService();

class EditBrandConnector extends Component {
  constructor(props) {
    super(props);
    let creating;
    if (this.props.match.params.hasOwnProperty("id")) creating = false;
    else creating = true;
    this.state = {
      name: "",
      manager: "",
      email: "",
      telephone: "",
      companyId: "",
      key: "",
      tag: "",
      channelId: "",
      number: Math.floor(Math.random() * 8999) + 1000,
      availableConnections: 0,
      unlockZones: false,
      loading: !creating,
      creating: creating,
      success: false
    };
    companyService.reload();
    channelService.reload();
    this.handleChange = this.handleChange.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTag = this.handleTag.bind(this);
    if (!creating) this.loadData();
  }
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleTag(event) {
    this.setState({ tag: event.target.value.replace(/\s/g, "").toUpperCase() });
  }
  handleName(event) {
    this.setState({
      name: event.target.value,
      tag: event.target.value
        .replace(/\s/g, "")
        .substring(0, 4)
        .toUpperCase()
    });
  }
  handleSubmit(event, newNumber) {
    event.preventDefault();
    let self = this;
    const {
      name,
      manager,
      email,
      telephone,
      companyId,
      channelId,
      unlockZones,
      tag,
      number,
      availableConnections
    } = this.state;
    let req = {
      name: name,
      manager: manager,
      email: email.toLowerCase(),
      telephone: telephone,
      companyId: companyId,
      channelId: channelId,
      unlockZones: unlockZones ? true : false,
      key: tag + "-" + (newNumber ? newNumber : number),
      tag: tag,
      number: newNumber ? newNumber : number,
      availableConnections: availableConnections
    };
    if (tag.length !== 4)
      return self.context.getNotificationSystem().addNotification({
        title: "No se pudo crear la Marca",
        message: "El Tag tiene que tener cuatro letras.",
        level: "warning"
      });

    return brandService
      .createOrEdit(req, this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.setState({
            success: true
          });
          self.context.getNotificationSystem().addNotification({
            message:
              "Se ha " +
              (self.state.creating ? "creado" : "editado") +
              " exitosamente la Marca " + req.name + ", con la KEY: " +
              req.key +
              ".",
            level: "success"
          });
        } else if (data.error.details.codes.tag) {
          self.context.getNotificationSystem().addNotification({
            title: "No se pudo crear la Marca",
            message: "El Tag ya existe",
            level: "error"
          });
        } else if (data.error.details.codes.key) {
          self.context.getNotificationSystem().addNotification({
            title: "La key ya existe",
            message: "Se intentara nuevamente con otro numero aleatorio",
            level: "warning"
          });
          self.handleSubmit(event, Math.floor(Math.random() * 8999) + 1000);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  loadData() {
    let self = this;
    brandService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          data.loading = false;
          data.unlockZones = data.unlockZones ? "true" : "";
          self.setState(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const {
      creating,
      name,
      telephone,
      manager,
      unlockZones,
      tag,
      number,
      email,
      loading,
      success,
      companyId,
      channelId,
      availableConnections
    } = this.state;
    let key = tag + "-" + number;
    if (success) return <Redirect to="/brands" push={true} />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      {creating ? "Agregando Marca" : "Editando Marca"}
                    </CardTitle>
                    <div className="small text-muted">{name}</div>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block" />
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="name"
                          value={name}
                          onChange={this.handleName}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Tag</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="tag"
                          value={tag}
                          maxLength="4"
                          onChange={this.handleTag}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Key</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" readOnly id="key" value={key} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            Cantidad de Conexiones
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="number"
                          disabled={loading}
                          id="availableConnections"
                          value={availableConnections}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Encargado</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="manager"
                          value={manager}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Email</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="text"
                          disabled={loading}
                          id="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Telefono</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          disabled={loading}
                          id="telephone"
                          value={telephone}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Canal</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="channelId"
                          value={channelId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona un Canal</option>
                          {this.props.channels.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Permitir crear Zonas</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          id="unlockZones"
                          value={unlockZones}
                          onChange={this.handleChange}
                          disabled={loading}
                        >
                          <option value="">No</option>
                          <option value="true">Si</option>
                        </Input>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Compañia</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="select"
                          id="companyId"
                          value={companyId}
                          onChange={this.handleChange}
                        >
                          <option value="">Selecciona una Compañia</option>
                          {this.props.companies.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                      <Button color="success" className="float-right">
                        {creating ? "Agregar" : "Editar"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

EditBrandConnector.contextTypes = {
  getNotificationSystem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    companies: state.companies,
    user: state.user,
    channels: state.channels
  };
};

const EditBrand = connect(
  mapStateToProps,
  null
)(EditBrandConnector);

export default EditBrand;
