import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ButtonToolbar,
  ButtonGroup
} from "reactstrap";
import { Company } from "../../redux/actions/index";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { CompanyService } from "../../services/companyService";
import Brands from "../Brand";

let companyService = new CompanyService();

class ViewCompanyConnector extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  loadData() {
    let self = this;
    companyService
      .readByID(this.props.match.params.id)
      .then(function(data) {
        if (!data.hasOwnProperty("error")) {
          self.props.Company(data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    const { data } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">
                      Compañia: {data.name || "Cargando..."}
                    </CardTitle>
                  </Col>
                  <Col sm="7" className="d-sm-inline-block">
                    <ButtonToolbar
                      className="float-right"
                      aria-label="Toolbar with button groups"
                    >
                      <ButtonGroup className="mr-3" aria-label="First group">
                        <Link
                          to={"/companies/edit/" + this.props.match.params.id}
                          className="btn btn-outline-info btn-sm"
                        >
                          <i className="fa fa-edit" />
                        </Link>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="row">
                <h6 className="mb-1 col-sm-6">
                    Email Encargado: {data.email}
                  </h6>
                  <h6 className="mb-1 col-sm-6">
                    Telefono Encargado: {data.telephone}
                  </h6>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Brands id={this.props.match.params.id} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state.company };
};

const mapDispatchToProps = dispatch => {
  return {
    Company: company => dispatch(Company(company))
  };
};

const ViewCompany = connect(mapStateToProps, mapDispatchToProps)(
  ViewCompanyConnector
);

export default ViewCompany;
